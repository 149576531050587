var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"fill-height default--text px-3"},[_c('v-layout',{attrs:{"fill-height":"","column":"","mt-5":""}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-img',{staticClass:"logo-modal",attrs:{"src":"/img_layout/modal-logo.png","alt":"Logo tosano","contain":"","height":"80px","width":"80px"}})],1),(_vm.category && _vm.category.metaData && _vm.category.metaData.category_info)?_c('h3',{staticClass:"font-weight-bold text-center"},[_vm._v(" "+_vm._s(_vm.category.metaData.category_info.TITLE)+" ")]):_vm._e(),(_vm.headerProposals.length > 0)?_c('v-container',{staticClass:"header mb-3",attrs:{"fluid":_vm.isFluid}},[_c('div',{staticClass:"mt-5"},[_c(_vm.headerMode,{tag:"component",attrs:{"title":_vm.headerTitle,"proposals":_vm.headerProposals,"autoplay":true,"paginationClass":"pagination-header-address","cols":"1","sm":"auto","md":"auto","lg":"auto"}})],1)]):_vm._e(),(_vm.deliveryServices.length > 1)?_c('v-row',{staticClass:"mt-5 mb-3 tabs",style:({ height: _vm.$vuetify.breakpoint.smAndDown ? '100px' : '120px' }),attrs:{"no-gutters":"","justify":"space-between","align":"center"}},_vm._l((_vm.deliveryServices),function(service,idx){return _c('v-col',{key:service.deliveryServiceId,staticClass:"delivery-service-tab d-flex flex-column align-center justify-center flex-grow-1 h-100 rounded-md pa-3",class:service.deliveryServiceId === _vm.selectedDeliveryServiceId
            ? 'selected'
            : '',style:({ 'max-width': _vm.deliveryServiceButtonsWidth }),attrs:{"cols":_vm.deliveryServiceButtonsLength},on:{"click":function($event){return _vm.fetchAddresses(service, idx)}}},[_c('v-img',{staticClass:"delivery-service-tab-img",attrs:{"src":_vm.getServiceParams(service.deliveryServiceId).icon,"alt":_vm.getServiceParams(service.deliveryServiceId).name,"width":"100","contain":""}}),_c('span',{staticClass:"delivery-service-name white--text primary font-weight-bold text-uppercase"},[_vm._v(_vm._s(_vm.getServiceParams(service.deliveryServiceId).alias))])],1)}),1):_vm._e(),(_vm.getDeliveryServicesDescr)?_c('div',{staticClass:"minimum-shopping font-weight-bold text-center mb-3",domProps:{"innerHTML":_vm._s(_vm.getDeliveryServicesDescr)}}):_vm._e(),(
        _vm.category &&
          _vm.category.metaData &&
          _vm.category.metaData.category_info &&
          _vm.category.metaData.category_info.DESCRIPTION
      )?_c('div',{staticClass:"text-center text-body-2",domProps:{"innerHTML":_vm._s(_vm.category.metaData.category_info.DESCRIPTION)}}):_vm._e(),_c('v-card',{attrs:{"loading":_vm.loading,"flat":""}},[_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"white",attrs:{"color":"secondary","background-color":"white","hide-details":"","clearable":"","outlined":"","dense":"","label":_vm.$t(
                'navbar.service.' + _vm.selectedDeliveryServiceId + '.searchLabel'
              )},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}},[_c('v-icon',{attrs:{"slot":"append","color":"red"},slot:"append"},[_vm._v(" $search ")])],1)],1)],1),(_vm.deliveryService.deliveryServiceId === 2)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-btn',{attrs:{"color":"secondary","depressed":"","block":"","to":{ name: 'AddressEdit' }},on:{"click":function($event){return _vm.$emit('submit', false)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":""}},[_vm._v("$plus")]),_vm._v("INSERISCI NUOVO INDIRIZZO ")],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-btn',{attrs:{"color":"secondary","outlined":"","depressed":"","block":""},on:{"click":function($event){return _vm.subscribeSteamboat('XH1V7')}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("$boat")]),_vm._v("Consegna al vaporetto ")],1)],1)],1):_vm._e(),_c('v-list',{staticClass:"address-list",attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.shippingAddress),callback:function ($$v) {_vm.shippingAddress=$$v},expression:"shippingAddress"}},_vm._l((_vm.filteredAddresses),function(address){return _c('v-list-item',{key:address.addressId,attrs:{"two-line":""},on:{"click":function($event){return _vm.setAddress(address)}}},[_c('v-list-item-content',[_c('v-row',{staticClass:"d-flex flex-nowrap",attrs:{"no-gutters":"","justify":"space-between"}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(address.selected ? "$radioOn" : "$radioOff")+" ")]),_c('div',[(
                      address.addressClass && address.addressClass.length > 0
                    )?_c('div',{staticClass:"d-flex mb-1"},_vm._l((address.addressClass),function(addressClass){return _c('AddressClass',{key:addressClass['address-class-id'],staticClass:"mr-3",attrs:{"addressClass":addressClass}})}),1):_vm._e(),_c('v-list-item-title',{staticClass:"font-weight-bold text-uppercase"},[_vm._v(" "+_vm._s(address.addressName)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t( "navbar.address." + address.addressTypeId + ".list", address ))+" ")])],1),_c('v-spacer')],1)],1),_c('v-list-item-action',{staticClass:"flex-row align-center"},[(
                  address.addressId == _vm.cart.user.defaultStoreAddressId ||
                    address.addressId == _vm.cart.user.defaultDeliveryAddressId
                )?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-img',_vm._g(_vm._b({attrs:{"width":"32","src":"/img_layout/address/favourite-address.svg"}},'v-img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(address.addressType == "home" ? "Indirizzo preferito" : "Negozio preferito"))])]):_vm._e(),(address.addressType == 'work')?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("$boat")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("navbar.service.tooltip.deliveryType.boatDelivery")))])]):_vm._e(),(address.addressType == 'home')?_c('v-btn',{staticClass:"no-border",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.edit(address)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$edit")])],1):_vm._e(),(address.addressType == 'home')?_c('v-btn',{staticClass:"no-border",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.remove(address)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$delete")])],1):_vm._e()],1)],1)}),1)],1)],1),(_vm.footerProposals.length > 0)?_c('v-container',{staticClass:"footer footer-address",attrs:{"fluid":_vm.isFluid}},[_c(_vm.footerMode,{tag:"component",attrs:{"title":_vm.footerTitle,"proposals":_vm.footerProposals,"autoplay":true,"paginationClass":"pagination-footer-address","cols":"auto","sm":"auto","md":"auto","lg":"auto"}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }