<template>
  <div class="first-shop-modal-container" @click="clicked">
    <v-dialog
      v-model="showFirstShopModal"
      class="first-shop-dialog"
      content-class="first-shop-dialog-modal"
      max-width="600"
    >
      <div class="vuedl-layout__closeBtn" @click="closeTutorial">
        <v-icon>$close</v-icon>
      </div>
      <!-- cat title -->
      <div
        class="text-h1 text-center primary lighten-1 white--text font-weight-bold"
        v-html="title"
      ></div>
      <v-container class="text-center mt-2">
        <!-- cat descr -->
        <p class="paragraph descr secondary--text" v-html="description"></p>
        <!-- cat content -->
        <p class="paragraph content" v-html="content"></p>

        <!-- cat header banner -->
        <v-btn
          v-if="header && header.length > 0"
          color="secondary"
          class="modal-btn mt-3 mb-6"
          :to="header[0].proposalUrl"
          @click="closeTutorial"
          depressed
        >
          <span v-html="header[0].descr"></span>
        </v-btn>
      </v-container>

      <!-- image cat footer -->
      <div class="px-10" v-if="footer && footer.length > 0">
        <v-img :src="footer[0].img" alt="Immagine prima spesa"> </v-img>
      </div>
    </v-dialog>
  </div>
</template>
<style lang="scss">
.first-shop-dialog-modal {
  .paragraph {
    &.descr {
      font-size: 20px;
    }
    font-size: 18px;
    line-height: 1.3;
    p {
      margin-bottom: 0px;
    }
  }
  .modal-btn.v-btn {
    height: auto;
    .v-btn__content {
      font-size: 18px;
      max-width: 100%;
      span {
        white-space: pre-wrap;
      }
    }
  }
}
</style>
<script>
import clickHandler from "~/mixins/clickHandler";

import CategoryService from "~/service/categoryService";

import get from "lodash/get";
import { mapActions, mapState } from "vuex";
import { mapProposal } from "~/service/ebsn.js";

export default {
  name: "FirstShopModal",
  data() {
    return {
      firstModalCategory: {},
      showFirstShopModal: false
    };
  },
  mixins: [clickHandler],
  computed: {
    ...mapState({
      firstShopTime: state => state.app.firstShopTime
    }),
    ...mapProposal(
      {
        header: "header",
        footer: "footer"
      },
      "firstModalCategory"
    ),
    title() {
      return get(
        this.firstModalCategory,
        "metaData.category_info.TITLE",
        this.firstModalCategory.name
      );
    },
    description() {
      return get(
        this.firstModalCategory,
        "metaData.category_info.DESCRIPTION",
        null
      );
    },
    content() {
      return get(
        this.firstModalCategory,
        "metaData.category_info.CONTENT",
        null
      );
    }
  },
  methods: {
    ...mapActions({
      updateFirstShopTime: "app/updateFirstShopTime"
    }),
    closeTutorial() {
      this.updateFirstShopTime();
      this.showFirstShopModal = false;
    }
  },
  async mounted() {
    let vm = this;
    try {
      this.firstModalCategory = await CategoryService.getCategoryBySlug(
        "first-shop"
      );
      vm.showFirstShopModal =
        !vm.firstShopTime ||
        new Date().getTime() - vm.firstShopTime >
          global.config.reopenTimeFirstShopModal
          ? true
          : false;
    } catch (e) {
      console.log(e);
    }
  }
};
</script>
