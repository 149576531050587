import Vue from "vue";
import Vuetify from "vuetify/lib";
import i18n from "./i18n";
import "@/scss/_fonts.scss";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    //disable: true,
    light: true,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        default: {
          base: "#3C3C3C",
          lighten1: "#636363"
        },
        primary: {
          base: "#e31f24",
          lighten1: "#f4464a",
          lighten2: "#EA1D28",
          lighten3: "#F87575"
        },
        secondary: {
          base: "#294d99",
          lighten1: "#4866a2",
          lighten2: "#597dcb",
          lighten3: "#7E93BD",
          lighten4: "#6982B7",
          lighten5: "#E8EBF4"
        },
        accent: {
          base: "#F18227",
          lighten1: "#F9CDA1",
          lighten2: "#f4e3cd",
          lighten3: "#FCE6D4"
        },
        white: "#FFFFFF",
        black: "#000000",
        grey: {
          base: "#CCCCCC",
          lighten1: "#E5E5E5",
          lighten2: "#f0f0f0",
          lighten3: "#f3f3f3",
          darken1: "#666666"
        },
        green: {
          base: "#32ACA4"
        },
        yellow: {
          base: "#fed24a"
        },
        error: "#FF5252", // poli
        warning: "#f17e21", // poli
        success: "#4CAF50" // poli
      }
    }
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  icons: {
    values: {
      search: "icon-search",
      fastSearch: "icon-fast-search",
      heart: "icon-heart",
      heartfull: "icon-heartfull",
      profile: "icon-profile",
      cart: "icon-cart",
      clear: "icon-close",
      close: "icon-close",
      home: "icon-home",
      dots: "icon-dots",
      menu: "icon-menu",
      orders: "icon-package",
      products: "icon-products",
      minus: "icon-minus",
      plus: "icon-add",
      plusThin: "icon-add-thin",
      // cardArrow: "icon-card-arrow",
      print: "icon-print",
      // delete: "icon-delete",
      // clock: "icon-clock"
      complete: "icon-complete",
      cancel: "icon-close",
      delete: "icon-garbage", // delete (e.g. v-chip close)
      garbage: "icon-garbage",
      success: "icon-complete",
      info: "icon-info",
      warning: "icon-warning",
      error: "icon-warning",
      prev: "icon-prev",
      next: "icon-next",
      checkboxOn: "icon-checkbox-on",
      checkboxOff: "icon-checkbox-off",
      checkboxIndeterminate: "ZZZ",
      delimiter: "icon-delimiter", // for carousel
      sort: "icon-sort",
      expand: "icon-expand",
      expandLess: "icon-expand-less",
      eye: "icon-eye",
      eyeOff: "icon-eye-off",
      subgroup: "ZZZ",
      dropdown: "icon-dropdown",
      radioOn: "icon-radio-checked",
      radioOff: "icon-radio-unchecked",
      edit: "icon-edit",
      ratingEmpty: "icon-star-outline",
      ratingFull: "icon-star",
      ratingHalf: "icon-star-half",
      loading: "ZZZ",
      first: "ZZZ",
      last: "ZZZ",
      unfold: "ZZZ",
      file: "ZZZ",
      delivery: "icon-delivery",
      pickup: "icon-pickup",
      locker: "icon-locker",
      boat: "icon-boat",
      editcalendar: "icon-edit-calendar",
      day: "icon-sun",
      night: "icon-moon",
      storefront: "icon-storefront",
      noPackage: "icon-no-package",
      package: "icon-package",
      euro: "icon-euro",
      percent: "icon-percent",
      filter: "icon-filter",
      arrowBack: "icon-arrow-back",
      arrowForward: "icon-arrow-forward",
      arrowUpward: "icon-expand-less",
      arrowDownward: "icon-arrow-downward",
      arrowTopLeft: "icon-arrow-top-left",
      qrCode: "icon-qr-code",
      box: "icon-box",
      supportAgent: "icon-support-agent",
      location: "icon-location",
      infoFull: "icon-info-filled",
      fastDelivery: "icon-fast-delivery",
      chef: "ZZZ",
      creditcard: "icon-creditcard",
      microphone: "icon-microphone",
      logout: "icon-logout",
      receipt: "icon-receipt"
    }
  }
});
