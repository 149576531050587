<template>
  <div class="response-message">
    <v-alert
      type="error"
      dense
      transition="scale-transition"
      v-for="(error, index) in errors"
      :key="index"
    >
      {{ error.error }}
    </v-alert>
    <v-alert
      type="warning"
      dense
      transition="scale-transition"
      v-for="(error, index) in warnings"
      :key="index"
    >
      {{ error.error }}
    </v-alert>
    <v-alert
      type="info"
      outlined
      :icon="false"
      prominent
      color="text-color"
      transition="scale-transition"
      v-for="(info, index) in infos"
      :key="index"
    >
      {{ info.info }}
    </v-alert>
  </div>
</template>
<script>
import isEmpty from "lodash/isEmpty";
export default {
  name: "ResponseMessage",
  props: { response: { type: Object, required: true } },
  computed: {
    errors() {
      return isEmpty(this.response.errors) ? [] : this.response.errors;
    },
    warnings() {
      return isEmpty(this.response.warnings) ? [] : this.response.warnings;
    },
    infos() {
      return isEmpty(this.response.infos) ? [] : this.response.infos;
    }
  }
};
</script>
