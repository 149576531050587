<template functional>
  <div class="text-caption primary--text text-lighten-2 mt-2">
    <i18n path="checkout.acceptText" tag="span">
      <template v-slot:link1>
        <router-link
          :to="{
            name: 'Page',
            params: { pageName: 'condizioni-vendita' }
          }"
        >
          {{ parent.$t("checkout.textLink1") }}
        </router-link>
      </template>
      <template v-slot:link2>
        <router-link
          :to="{
            name: 'Page',
            params: { pageName: 'privacy-policy' }
          }"
        >
          {{ parent.$t("checkout.textLink2") }}
        </router-link>
      </template>
      <template v-slot:link3>
        <router-link
          :to="{
            name: 'Page',
            params: { pageName: 'cookie-policy' }
          }"
        >
          {{ parent.$t("checkout.textLink3") }}
        </router-link>
      </template>
    </i18n>
  </div>
</template>
<script>
export default {
  name: "AcceptRulesText"
};
</script>
