<template>
  <v-card flat v-if="isAuthenticated">
    <v-card-title class="secondary--text text-lighten-1 font-weight-bold">
      {{ $t("cart.fastPayment.title") }}
    </v-card-title>
    <CartInfoList :isFastPayment="true" />

    <v-divider class="my-3" v-if="paymentAuthList" />

    <div v-if="paymentAuthList" class="px-3">
      <div class="text-body-2 font-weight-bold secondary--text text-lighten-1">
        {{ $t("cart.fastPayment.yourCard") }}
      </div>
      <v-list class="rounded-md grey lighten-2">
        <v-list-item
          v-for="pa in paymentAuthList"
          :key="pa.userPaymentAuthId"
          @click="setPaymentAuthSelected(pa)"
        >
          <v-list-item-avatar rounded="0" class="payment-radio">
            <v-icon
              v-if="
                pa.userPaymentAuthId === paymentAuthSelected.userPaymentAuthId
              "
              >$radioOn</v-icon
            >
            <v-icon v-else>$radioOff</v-icon>
          </v-list-item-avatar>
          <v-list-item-avatar rounded="0" width="auto">
            <img
              :src="getCreditCardImage(pa.creditCard)"
              alt="Immagine carta di credito"
            />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ pa.creditCard | ccard }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ $t("cart.fastPayment.expires") }}:
              {{ pa.expireDate | ccexp }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>

    <div v-if="paymentAuthListWallet" class="px-3">
      <div class="text-body-2 font-weight-bold secondary--text text-lighten-1">
        {{ $t("cart.fastPayment.yourWallet") }}
      </div>
      <v-list class="rounded-md grey lighten-2">
        <v-list-item
          v-for="pa in paymentAuthListWallet"
          :key="pa.userPaymentAuthId"
          @click="setPaymentAuthSelected(pa)"
        >
          <v-list-item-avatar rounded="0" class="payment-radio">
            <v-icon
              v-if="
                pa.userPaymentAuthId === paymentAuthSelected.userPaymentAuthId
              "
              >$radioOn</v-icon
            >
            <v-icon v-else>$radioOff</v-icon>
          </v-list-item-avatar>
          <v-list-item-avatar rounded="0" width="auto">
            <img
              src="/credit-card/paypal-transparent-64px.png"
              alt="Logo PayPal"
            />
          </v-list-item-avatar>
          <v-list-item-content>
            {{ pa.creditCard }}
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>

    <v-divider class="my-3" v-if="paymentAuthSelected && prebilledAmount" />

    <v-row
      no-gutters
      v-if="paymentAuthSelected && prebilledAmount"
      class="text-body-1 font-weight-bold secondary--text text-lighten-1 px-3"
      justify="space-between"
    >
      <div>
        <!-- {{ $t("cart.fastPayment.amount") }} -->
        <div class="prebilled-amount">
          <v-tooltip
            top
            max-width="300"
            v-if="
              $te(
                'prebilled.cart.tooltipBaseOnPaymentTypeId.' +
                  paymentAuthSelected.paymentTypeId
              )
            "
            content-class="prebilled-amount-tooltip"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-row no-gutters>
                <v-icon color="secondary" class="mr-4" v-bind="attrs" v-on="on"
                  >$info
                </v-icon>
                <div>
                  {{ $t("prebilled.cart.text") }}
                </div>
              </v-row>
            </template>

            <span
              v-html="
                $t(
                  'prebilled.cart.tooltipBaseOnPaymentTypeId.' +
                    paymentAuthSelected.paymentTypeId
                )
              "
            />
          </v-tooltip>
          <div v-else>
            {{ $t("prebilled.cart.text") }}
          </div>
        </div>
      </div>
      <div>
        {{ $n(prebilledAmount, "currency") }}
      </div>
    </v-row>

    <v-divider class="my-3" />

    <v-card-actions v-if="!loading">
      <v-row no-gutters v-if="paymentAuthSelected" justify="space-between">
        <v-btn outlined color="secondary" depressed @click="close"
          >{{ $t("common.back") }}
        </v-btn>
        <v-btn color="secondary" v-on:click="buy" depressed>
          {{ $t("paymentTypeList.confirm") }}
        </v-btn>
      </v-row>
      <div v-else>
        <div
          class="
          mx-1
          pa-2
          accent
          white--text
          text-caption
          font-weight-bold
        "
          v-html="$t('cart.fastPayment.noCard')"
        ></div>
        <v-btn
          depressed
          text
          block
          class="primary mt-3"
          @click="goToPaymentMethods"
        >
          {{ $t("cart.fastPayment.btn") }}
        </v-btn>
      </div>
    </v-card-actions>
    <ResponseMessage :response="response" class="pa-3" />
  </v-card>
</template>

<style scoped lang="scss">
::v-deep .list {
  .cart-info-button.v-btn--disabled {
    color: $secondary !important;
  }
}
</style>
<script>
import CartInfoList from "@/components/cart/CartInfoList.vue";
import ResponseMessage from "@/components/ResponseMessage.vue";

import { payment } from "~/components/payment/gateways/gatewayUtilsService";

import creditCard from "~/mixins/creditCart";

import CartService from "~/service/cartService";

import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [creditCard],
  data() {
    return {
      error: null,
      selectedPaymentAuthId: null,
      paymentAuthSelected: null,
      paymentAuthList: null,
      prebilledAmount: null,
      response: {},
      loading: true
    };
  },
  components: {
    ResponseMessage,
    CartInfoList
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated",
      user: "cart/getUser"
    })
  },
  methods: {
    ...mapActions({
      buyCart: "cart/buyCart",
      lockCart: "cart/lockCart"
    }),
    close() {
      this.$emit("submit", false);
    },
    goToPaymentMethods() {
      this.$router.push("/profile/payment-method");
      this.close();
    },
    setPaymentAuthSelected(pa) {
      this.paymentAuthSelected = pa;
    },
    async getPrebilledAmount() {
      this.prebilledAmount = await CartService.getPreBilledAmount(
        this.paymentAuthSelected.paymentTypeId ||
          global.config.defaultPaymentTypeId
      );
    },
    async buy() {
      let vm = this;
      vm.loading = true;
      try {
        let cartLocked = await vm.lockCart();
        if (cartLocked) {
          let cart = await vm.buyCart({
            paymentTypeId: vm.paymentAuthSelected.paymentTypeId,
            userPaymenthAuthId: vm.paymentAuthSelected.userPaymentAuthId
            // paymentData: {}
          });
          if (cart) {
            vm.payResponseOk(cart);
          } else {
            this.$router.push("/payment-error");
          }
        }
      } catch (err) {
        vm.response = err;
      } finally {
        vm.loading = false;
        this.$emit("submit", true);
      }
    },
    payResponseOk(cart) {
      let vm = this;
      let orderId = null;
      global.EventBus.$emit("pay-response-ok", cart);
      if (cart && cart.paymentUrl) {
        orderId = cart.order ? cart.order.orderId : cart.orderId;
        vm.doRedirect(cart.paymentUrl, cart.paymentParameters, "post", orderId);
        this.loading = false;
      } else {
        orderId = cart.order ? cart.order.orderId : cart.orderId;
        this.$router.push("/cart/payment-completed/" + orderId);
        this.loading = false;
      }
    },
    doRedirect(paymentUrl, paymentParameters, method, orderId) {
      payment(paymentUrl, paymentParameters, method, orderId);
    }
  },
  async mounted() {
    let paymentAuthList = await CartService.getPaymentAuthList(22);
    this.loading = false;
    if (paymentAuthList.length > 0) {
      //TEST MODE
      // paymentAuthList = [
      //   {
      //     active: "1",
      //     creditCard: "525599******9992",
      //     creditCardCircuit: "",
      //     creditCardName: "",
      //     defaultAuth: true,
      //     expireDate: "1225",
      //     params: "",
      //     paymentTypeId: 13,
      //     userId: 149482,
      //     userPaymentAuthId: 733
      //   }
      // ];
      this.paymentAuthSelected = paymentAuthList.find(p => {
        return p.active === "1";
      });
      this.paymentAuthList = paymentAuthList.filter(p => {
        return p.active === "1";
      });
      if (this.paymentAuthSelected == null) this.paymentAuthList[0];

      this.getPrebilledAmount();
    }
    let paymentAuthListWallet = await CartService.getPaymentAuthList(27);
    this.loading = false;
    if (paymentAuthListWallet.length > 0) {
      //TEST MODE
      // paymentAuthList = [
      //   {
      //     active: "1",
      //     creditCard: "525599******9992",
      //     creditCardCircuit: "",
      //     creditCardName: "",
      //     defaultAuth: true,
      //     expireDate: "1225",
      //     params: "",
      //     paymentTypeId: 13,
      //     userId: 149482,
      //     userPaymentAuthId: 733
      //   }
      // ];
      this.paymentAuthSelected = paymentAuthListWallet.find(p => {
        return p.active === "1";
      });
      this.paymentAuthListWallet = paymentAuthListWallet.filter(p => {
        return p.active === "1";
      });
      if (this.paymentAuthSelected == null) this.paymentAuthListWallet[0];

      this.getPrebilledAmount();
    }
  }
};
</script>
