<template>
  <v-container fluid class="pa-0">
    <v-card
      class="pt-2 px-3 text-right grey lighten-3 h-100"
      flat
      tile
      v-if="cart.cartId > 0"
    >
      <v-card-text class="secondary-text pb-0">
        <div
          v-if="deliveryServiceId == 2"
          class="text-h3 d-flex justify-space-between secondary--text mt-2"
          style="line-height: 10px"
        >
          <span>{{ $t("cartSummary.deliveryFee") }}</span>
          <span>{{ $n(cart.deliveryFee, "currency") }}</span>
        </div>

        <div
          class="text-h2 d-flex justify-space-between align-center font-weight-bold secondary--text"
        >
          <span>{{ $t("cartSummary.cartGrossTotal") }}</span
          ><span class="value">{{ $n(cart.totalPrice, "currency") }}</span>
        </div>
      </v-card-text>
      <v-card-actions class="justify-end pb-0">
        <v-row no-gutters>
          <v-col cols="6" class="pa-1" v-if="cart.totalItems > 0">
            <v-tooltip top open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="accent"
                  class="white--text"
                  min-width="95%"
                  v-bind="attrs"
                  v-on="on"
                  :x-large="!$vuetify.breakpoint.xs"
                  depressed
                  :disabled="
                    profileLevel < minProfileLevel || cart.totalItems == 0
                  "
                  :loading="loading"
                  @click="fastPayment"
                >
                  {{ $t("checkout.fastPaymentBtn") }}
                </v-btn>
              </template>
              <span v-html="$t('checkout.fastPaymentBtnTooltip')" />
            </v-tooltip>
          </v-col>
          <v-col cols="6" class="pa-1" v-if="cart.totalItems > 0">
            <v-btn
              class="checkout-btn"
              to="/checkout"
              color="primary"
              min-width="95%"
              :x-large="!$vuetify.breakpoint.xs"
              depressed
              >{{ $t("cart.button.goToPayment") }}</v-btn
            >
          </v-col>
        </v-row>
        <!-- <v-row>
        </v-row> -->
      </v-card-actions>
      <v-card-text class="pt-0 pb-3">
        <AcceptRulesText />
      </v-card-text>
    </v-card>
    <v-card v-else>{{ $t("no-cart") }}</v-card>
  </v-container>
</template>
<style scoped lang="scss">
.discount {
  margin: 3px 0;
  padding: 10px 30px;
  max-height: 40px;
}
.value {
  min-width: 150px;
}
</style>
<script>
import FastPayment from "@/components/cart/FastPayment.vue";
import AcceptRulesText from "@/components/payment/AcceptRulesText.vue";
import DeliveryService from "~/service/deliveryService";

import get from "lodash/get";
import { mapGetters, mapState, mapActions } from "vuex";
export default {
  name: "CartSummary",
  data() {
    return {
      loading: false,
      minProfileLevel: global.config.minProfileLevel
    };
  },
  components: {
    AcceptRulesText
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      profileLevel: "cart/profileLevel",
      deliveryServiceId: "cart/getDeliveryServiceId"
    }),
    confirmed() {
      return get(this.cart.user, "profile.confirmed", false);
    }
  },
  methods: {
    ...mapActions({
      emptyCart: "cart/emptyCart",
      updatedDrawerRight: "app/updatedDrawerRight",
      removeGiftCode: "cart/removeGiftCode"
    }),
    closeDrawer() {
      this.updatedDrawerRight(false);
    },
    removeCode(giftCodeId) {
      this.removeGiftCode(giftCodeId);
    },
    async fastPayment() {
      let _this = this;
      if (!_this.confirmed) {
        global.EventBus.$emit("error", {
          message: this.$t("checkout.confirmEmailSubTitle")
        });
      } else {
        let res = await _this.$dialog.show(FastPayment, {
          waitForResult: true,
          width: 500
        });
        if (res) {
          this.closeDrawer();
          // let items = [];
          // forEachCartItem(_this.cart, function(item) {
          //   items.push(item);
          // });
          // await ListService.addProductsToList(res.listId, items);
        }
      }
    },
    changeService() {},
    changeTimeslot() {},
    changeDelivery() {},
    async getDeliveryFee() {
      this.deliveryFee =
        this.deliveryServiceId == 2
          ? await DeliveryService.getDeliveryFee()
          : (this.deliveryFee = null);
    }
  },
  watch: {
    deliveryServiceId() {
      this.getDeliveryFee();
    }
  }
};
</script>
