<template>
  <div class="cart-item" :class="{ 'mb-1': full }">
    <v-list-item
      color="secondary"
      :to="{
        name: 'Product',
        params: { slug: item.product.slug }
      }"
      :class="{ 'py-3': !full }"
    >
      <v-row no-gutters class="w-100" align="center" justify="space-between">
        <v-col cols="12" :sm="full ? 5 : 6" class="pa-0">
          <v-row no-gutters>
            <v-list-item-avatar
              :width="full ? '80' : '60'"
              :height="full ? '80' : '60'"
              rounded="0"
            >
              <img
                :src="item.product.mediaURL"
                :alt="'Immagine prodotto'"
                onerror="this.onerror=null;this.src='/no-icon.png'"
              />
            </v-list-item-avatar>

            <v-list-item-content class="default--text pa-0 pa-sm-2">
              <div
                class="font-weight-bold"
                :class="full ? 'text-caption' : 'text-body-2'"
              >
                {{ item.product.name }}
              </div>
              <v-list-item-subtitle
                class=" default--text text-uppercase font-weight-bold"
                :class="full ? 'custom-text-small' : 'text-caption'"
              >
                {{ item.product.shortDescr }}
              </v-list-item-subtitle>

              <!-- <v-list-item-subtitle>
          <span
            class="weight-unit"
            v-if="
              item.product.productInfos.TIPOLOGIA == 'Pezzo' &&
                item.product.priceUmDisplay
            "
          >
            {{ $n(item.product.priceUmDisplay, "currency") }}/{{
              item.product.weightUnitDisplay
            }},
          </span>
          {{ item.product.description }}
        </v-list-item-subtitle> -->
            </v-list-item-content>
          </v-row>
        </v-col>
        <v-col cols="12" :sm="full ? 7 : 6" lg="6" class="pa-0">
          <v-list-item-action class="mr-0 w-100">
            <div
              class="d-flex align-center px-2 px-sm-0 w-100"
              :class="
                full || $vuetify.breakpoint.xs
                  ? 'justify-space-between'
                  : 'justify-end'
              "
            >
              <ProductPrice
                class="product-price"
                :product="item.product"
                :showStandard="false"
                v-if="full"
                :full="full"
              ></ProductPrice>
              <ProductPromo
                :warehousePromo="item.product.warehousePromo"
                v-if="false"
              />
              <div
                class="qty-wrap"
                :class="{ 'not-empty': quantity > 0, 'w-100': full }"
              >
                <a
                  role="button"
                  class="minus"
                  @click.prevent.stop="minus(true)"
                  @mousedown.stop
                >
                  <v-icon>$minus</v-icon>
                </a>
                <div class="val-cnt">
                  <span class="val">{{ quantity }} {{ unit }}</span>
                  <span class="small">{{ quantityPerUnit }}</span>
                </div>
                <a
                  role="button"
                  class="plus"
                  @click.prevent.stop="plus(true)"
                  @mousedown.stop
                >
                  <v-icon v-if="quantity > 0">$plus</v-icon>
                  <v-icon v-else>$cart</v-icon>
                </a>
              </div>
              <div class="gross-total price font-weight-bold">
                {{ $n(item.grossTotal, "currency") }}
              </div>
              <v-btn
                icon
                aria-label="Rimuovi dal carrello"
                @click.prevent="remove"
                @mousedown.stop
                color="promo"
              >
                <v-icon color="secondary">$delete</v-icon>
              </v-btn>
            </div>
          </v-list-item-action>
        </v-col>
      </v-row>
    </v-list-item>
    <v-row
      v-if="full"
      no-gutters
      align-content="center"
      class="alternatives my-1"
    >
      <v-checkbox
        dense
        color="secondary"
        hide-details
        class="accept-alternatives mt-0 pt-0 mr-2"
        v-model="acceptAlternative"
      >
        <template v-slot:label>
          <span>{{ $t("cart.acceptAlternatives") }}</span>
        </template>
      </v-checkbox>
    </v-row>
    <v-dialog v-model="removeDialog" max-width="400">
      <div class="vuedl-layout__closeBtn" @click="removeDialog = false">
        ×
      </div>
      <v-card>
        <v-card-text class="body-1 pt-8">
          {{
            $t("message.remove", {
              name: this.product.name,
              quantity: this.quantity,
              unit: this.unit
            })
          }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="removeDialog = false">
            No
          </v-btn>

          <v-btn
            class="secondary"
            text
            @click.prevent="doRemove"
            @mousedown.stop
          >
            Si
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style global lang="scss">
.custom-text-small {
  font-size: 10px !important;
}
.cart-item {
  .product-price {
    min-width: 90px;
    text-align: right;
  }
  .accept-alternatives {
    span {
      font-size: 10px;
    }
  }
  .v-icon.icon-garbage {
    font-size: 20px;
  }
}
</style>
<script>
import productMixin from "~/mixins/product";
import ProductPrice from "@/components/product/ProductPrice";
import ProductPromo from "@/components/product/ProductPromo";
import get from "lodash/get";
import { mapActions } from "vuex";
export default {
  name: "CartItem",
  components: {
    ProductPrice,
    ProductPromo
  },
  props: {
    full: { type: Boolean, default: false },
    item: { type: Object, required: true }
  },
  mixins: [productMixin],
  data() {
    return { removeDialog: false };
  },
  computed: {
    acceptAlternative: {
      get() {
        return (
          get(this.item, "cartItemInfo.accept_alternatives", "true") == "true"
        );
      },
      set(value) {
        this.setCartItemInfo({
          items: this.item,
          name: "accept_alternatives",
          value: "" + value
        });
      }
    },
    product() {
      return this.item.product;
    },
    classCardContainerObject: function() {
      if (this.full && this.$vuetify.breakpoint.mdAndUp) {
        return "full d-flex flex-column justify-space-around";
      } else {
        if (!this.$vuetify.breakpoint.xs) {
          return "d-flex flex-row justify-space-around";
        }
        return "XS";
      }
    }
  },
  methods: {
    ...mapActions({
      setCartItemInfo: "cart/setCartItemInfo"
    }),
    remove() {
      this.removeDialog = true;
    },
    doRemove() {
      this.removeDialog = false;
      this.removeItem(this.item);
    }
  }
};
</script>
