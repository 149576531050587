<template>
  <v-app
    :class="[
      getKeyboardOpen ? classes + ' keyboard-open' : classes,
      { 'platform-ios': platformIos }
    ]"
  >
    <Navbar
      v-if="showApp"
      :key="key"
      :deliveryFee="deliveryFee"
      :barShadow="barShadow"
    />
    <v-navigation-drawer
      v-if="showApp"
      v-model="drawerLeft"
      touchless
      left
      app
      clipped
      class="categories"
      width="266"
    >
      <CategoryTree />
    </v-navigation-drawer>
    <v-navigation-drawer
      v-if="showApp"
      v-model="drawerRight"
      touchless
      temporary
      clipped
      right
      app
      width="600"
      class="cart-drawer white"
    >
      <div
        class="cart-info"
        :class="
          deliveryServiceId == 2 && isShowingDeliveryFee
            ? 'show-delivery-fee'
            : ''
        "
      >
        <CartInfoList
          :isCart="true"
          class="infos pt-2 pb-5 pb-sm-0 grey lighten-3"
        />
        <CartItemList
          class="items-list"
          :full="$vuetify.breakpoint.xs"
          :showSectors="true"
        />
        <DeliveryFee
          :showList="false"
          :deliveryFee="deliveryFee"
          @changeShowingDeliveryFee="changeShowingDeliveryFee"
        />
        <CartSummary class="summary py-0" />
      </div>
    </v-navigation-drawer>

    <!-- 
      drawer supplementare destro per visualizzazione link per mobile
    -->
    <v-navigation-drawer
      class="links-list secondary lighten-1"
      v-if="showApp"
      :stateless="!isMobile"
      v-model="drawerLinks"
      touchless
      right
      app
      @close="drawerLinks = false"
    >
      <MenuLinksList
        :links="footerLinks.concat(footerBottomLinks)"
        @close="drawerLinks = false"
      />
    </v-navigation-drawer>
    <v-main>
      <!-- <Tutorial v-if="isCordova" /> -->
      <FirstShopModal v-if="!isPrerender" />
      <div class="content-wrap">
        <v-skeleton-loader
          type="actions, image, article,image"
          v-if="loading"
          :loading="loading"
        ></v-skeleton-loader>
        <router-view v-else></router-view>
      </div>
      <v-footer v-if="showApp" class="pa-0">
        <Footer :links="footerLinks" :bottomLinks="footerBottomLinks" />
        <!-- class="d-none d-sm-block" -->
      </v-footer>
    </v-main>

    <v-fab-transition v-if="showApp">
      <v-btn
        class="scroll-on-top-btn"
        v-scroll="onScroll"
        v-show="fab"
        fab
        fixed
        bottom
        left
        color="primary lighten-1"
        @click="toTop"
        height="45"
        width="45"
      >
        <v-icon>$arrowUpward</v-icon>
      </v-btn>
    </v-fab-transition>
    <!-- <Footer
      v-if="isCordova && $vuetify.breakpoint.smAndDown"
      class="d-flex flex-column"
    /> -->
  </v-app>
</template>
<style lang="scss">
.links-list {
  padding-bottom: 81px;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    min-width: 75%;
  }
  @media #{map-get($display-breakpoints, 'sm-only')} {
    min-width: 50%;
  }
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    min-width: 35%;
  }
}
.is-cordova {
  .links-list,
  .categories {
    .v-navigation-drawer__content {
      // transform: translateY(-100%);
      -webkit-box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.6);
      box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.6);
    }
  }
}
.scroll-on-top-btn {
  bottom: 100px;
}
</style>
<script>
import CartSummary from "@/components/cart/CartSummary.vue";
import CartInfoList from "@/components/cart/CartInfoList.vue";
import CartItemList from "@/components/cart/CartItemList.vue";
import DeliveryFee from "@/components/delivery/DeliveryFee.vue";
import CategoryTree from "@/components/navigation/CategoryTree.vue";
import MenuLinksList from "@/components/navigation/MenuLinksList.vue";
import CheckDisclaimerDialog from "@/components/profile/CheckDisclaimerDialog.vue";
import FirstShopModal from "@/components/FirstShopModal.vue";
// import Tutorial from "@/components/Tutorial";

import login from "~/mixins/login";
import version from "~/mixins/version";

import CmService from "~/service/cmService";
import DeliveryService from "~/service/deliveryService";
import UserService from "~/service/userService";

import { mapActions, mapGetters } from "vuex";
import findIndex from "lodash/findIndex";
import analyticsService from "./commons/service/analyticsService";

export default {
  name: "App",
  components: {
    CategoryTree,
    CartInfoList,
    CartSummary,
    CartItemList,
    DeliveryFee,
    // eslint-disable-next-line vue/no-unused-components
    MenuLinksList,
    FirstShopModal,
    Footer: () =>
      process.env.VUE_APP_CORDOVA_PLATFORM
        ? import("@/components/navigation/BottomNavBar.vue")
        : import("@/components/navigation/Footer.vue"),
    Navbar: () =>
      process.env.VUE_APP_CORDOVA_PLATFORM
        ? import("@/components/navigation/NavbarMobile.vue")
        : import("@/components/navigation/Navbar.vue")
  },
  mixins: [login, version],
  data() {
    return {
      fab: false,
      load404: false,
      initialHref: window.location.href,
      loading: true,
      footerLinks: [],
      footerBottomLinks: [],
      key: 1,
      deliveryFee: null,
      classes: process.env.CORDOVA_PLATFORM ? "is-cordova" : "",
      keyboardOpen: false,
      isShowingDeliveryFee: false,
      barShadow: false,
      attValue: false,
      attResponse: false,
      platformIos: false
    };
  },
  metaInfo: {
    title: "LaTuaSpesa",
    titleTemplate: "%s - l'ecommerce secondo Iper Tosano",
    meta: [
      { charset: "utf-8" },
      {
        vmid: "description",
        name: "description",
        content:
          "La tua spesa è il nuovissimo servizio di Iper Tosano che consente di fare acquisti su un vasto assortimento di prodotti in totale comodità da casa, con semplicità e rapidità"
      },
      {
        name: "keywords",
        content:
          "Tosano Tosano SpesaOnline LaTuaSpesa Spesa Online supermercato"
      },
      { name: "viewport", content: "width=device-width, user-scalable=no" },
      { name: "author", content: "Digitelematica s.r.l." }
    ]
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    ...mapGetters({
      needRestart: "needRestart",
      deliveryServiceId: "cart/getDeliveryServiceId",
      isAuthenticated: "cart/isAuthenticated"
    }),
    drawerLeft: {
      get() {
        return this.$store.state.app.drawerLeft;
      },
      set(value) {
        this.$store.commit("app/updatedDrawerLeft", value);
      }
    },
    drawerRight: {
      get() {
        return this.$store.state.app.drawerRight;
      },
      set(value) {
        this.$store.commit("app/updatedDrawerRight", value);
      }
    },
    drawerLinks: {
      get() {
        return this.$store.state.app.drawerLinks;
      },
      set(value) {
        this.$store.commit("app/updatedDrawerLinks", value);
      }
    },
    getKeyboardOpen: function() {
      return this.keyboardOpen;
    },
    showApp: function() {
      return !this.load404;
    }
  },
  methods: {
    ...mapActions({
      updateAuth: "cart/updateAuth",
      resetFilters: "category/resetFilters",
      updateLastStartTime: "app/updateLastStartTime",
      loadCart: "cart/loadCart"
    }),
    isPlatformIos() {
      if (this.isCordova && window.cordova.platformId.toLowerCase() === "ios") {
        this.platformIos = true;
      }
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
      let footerOffsetHeight =
        document.body.scrollHeight -
        (document.getElementById("footer") &&
        document.getElementById("footer").offsetHeight
          ? document.getElementById("footer").offsetHeight
          : 0) -
        document.getElementsByClassName("appbar")[0].offsetHeight;
      this.barShadow = window.pageYOffset > footerOffsetHeight;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    fetchFooterLinksMenu() {
      CmService.getMenuByLocation("footer").then(
        data => (this.footerLinks = data)
      );
      CmService.getMenuByLocation("footer-bottom").then(
        data => (this.footerBottomLinks = data)
      );
    },
    async onResume() {
      var _this = this;
      var hash;
      global.EventBus.$emit("resume");
      setTimeout(async function() {
        hash = findIndex(global.config.hashNotReload, function(o) {
          return window.location.hash.indexOf(o) !== -1;
        });
        var needRestart =
          new Date().getTime() - _this.$store.state.app.lastStartTime >
          global.config.reloadAfterMillis;
        if (hash === -1 && needRestart) {
          global.EventBus.$emit("restart");
        } else {
          if (hash === -1 && _this.$cookies.get("X-Ebsn-Session")) {
            await _this.loadCart();
          }
          global.EventBus.$emit("reload");
        }
      }, 100);
    },
    async handleLogin() {
      this.doLogin("response419");
    },
    async onDeepLinks(path, queryString) {
      var _this = this;
      var currentQuery = {};
      console.log("onDeepLinks :::: ", path);
      console.log("onDeepLinks queryString :::: ", queryString);
      console.log("_this.$router :::: ", _this.$router);
      setTimeout(async function() {
        if (queryString) {
          currentQuery.q = queryString.replace("q=", "").replace(/%20/g, " ");
          console.log("onDeepLinks currentQuery :::: ", currentQuery);
          _this.$router.push({
            path: "/search",
            name: "Search",
            query: currentQuery
          });
        } else {
          _this.$router.push(path);
        }
      }, 100);
    },
    changeShowingDeliveryFee(isShowing) {
      this.isShowingDeliveryFee = isShowing;
    },
    resolveRoute(data) {
      if (global.config.domainWhitelist.includes(data.host)) {
        let resolved = this.$router.resolve({
          path: data.path
        });
        if (
          resolved &&
          resolved.route.matched.length > 0 &&
          resolved.route.name != "DefaultRoute"
        ) {
          this.$router.push(resolved.route);
        } else {
          if (this.$platform_is_cordova && data.url.indexOf("http") > -1) {
            //if app open on _system browser
            window.cordova.InAppBrowser.open(data.url, "_system");
          }
        }
      } else {
        if (this.$platform_is_cordova && data.url.indexOf("http") > -1) {
          //if app open on _system browser
          window.cordova.InAppBrowser.open(data.url, "_system");
        }
      }
    },
    onAppTrackingTransparency() {
      var _this = this;
      // eslint-disable-next-line no-undef
      let platform = device ? device.platform.toLowerCase() : "ios";
      if (
        _this.$platform_is_cordova &&
        platform == "ios" &&
        window.plugins.impacTracking
      ) {
        console.log("Calling onAppTrackingTransparency");
        window.plugins.impacTracking.trackingAvailable(
          function(available) {
            if (!available) {
              console.log(
                "Tracciamento non disponibile o disabilitato dall'utente"
              );
              window.plugins.impacTracking.canRequestTracking(
                function(result) {
                  // Show results in the console
                  console.log(result);
                  if (result == true) {
                    // eslint-disable-next-line no-undef
                    Cookiebot.hide();
                    window.plugins.impacTracking.requestTracking(
                      undefined,
                      function(res) {
                        console.log("AppTracking enabled: " + res);
                        _this.attValue = res;
                        if (res == true) {
                          // eslint-disable-next-line no-undef
                          Cookiebot.submitCustomConsent(true, true, true);
                        }
                        if (res == false) {
                          // eslint-disable-next-line no-undef
                          Cookiebot.hide();
                        }
                      },
                      function(err) {
                        console.log(err);
                      }
                    );
                  } else {
                    console.log(
                      "Tracciamento non disponibile o disabilitato dall'utente"
                    );
                  }
                },
                function(err) {
                  console.log(err);
                  console.log(
                    "Tracciamento non disponibile o disabilitato dall'utente"
                  );
                }
              );
            } else {
              window.plugins.impacTracking.requestTracking(
                undefined,
                function(res) {
                  console.log(
                    "Calling onAppTrackingTransparency; already defined"
                  );
                  console.log("AppTracking enabled: " + res);
                  _this.attValue = res;
                  // eslint-disable-next-line no-undef
                  var stats = Cookiebot.consent.statistics;
                  // eslint-disable-next-line no-undef
                  var mrkg = Cookiebot.consent.marketing;
                  // eslint-disable-next-line no-undef
                  var pref = Cookiebot.consent.preferences;
                  // eslint-disable-next-line no-undef
                  Cookiebot.submitCustomConsent(pref, stats, mrkg);
                  if (res == false) {
                    // eslint-disable-next-line no-undef
                    Cookiebot.hide();
                  }
                },
                function(err) {
                  console.log(err);
                }
              );
            }
          },
          function(err) {
            console.log(err);
          }
        );
      }
    },
    async refreshLogin() {
      if (global.config.pushNotificationEnabled) {
        console.log("REFRESH LOGIN - REGISTER FIREBASE DEVICE");
        // Al Deviceready facciamo un refresh login per controllare se l'utente è ancora collegato
        // await SecurityService.refreshLogin();
        //await this.registerFirebaseDevice();
      }
    },
    async getDeliveryFee() {
      this.deliveryFee =
        this.deliveryServiceId == 2
          ? await DeliveryService.getDeliveryFee()
          : (this.deliveryFee = null);
    },
    async checkMissingDisclaimer() {
      let response = await UserService.checkUserDisclaimer(2);
      if (
        response &&
        response.registrationModules &&
        response.registrationModules.length > 0
      ) {
        await this.openDialog(CheckDisclaimerDialog, {
          waitForResult: true,
          fullscreen: false,
          width: 750
        });
      }
    }
  },
  async created() {
    var _this = this;
    _this.load404 = false;
    this.fetchFooterLinksMenu();
    this.getDeliveryFee();
    try {
      if (_this.$cookies.get("X-Ebsn-Session")) {
        await _this.loadCart();
      } else if (window.localStorage.getItem("X-Ebsn-Session")) {
        let session = window.localStorage.getItem("X-Ebsn-Session");
        _this.$cookies.set("X-Ebsn-Session", session);

        let account = window.localStorage.getItem("X-Ebsn-Account");
        _this.$cookies.set("X-Ebsn-Account", account);

        await _this.loadCart();
      } else {
        analyticsService.initCustomDimension();
      }
    } finally {
      _this.loading = false;
    }

    global.EventBus.$on("isNotLoggedIn", () => {
      _this.updateAuth();
      _this.handleLogin();
    });
    global.EventBus.$on("restart", () => {
      _this.updateLastStartTime();
      window.location.reload();
    });
    global.EventBus.$on("resetFilters", () => {
      _this.resetFilters(Math.random());
    });
    setTimeout(function() {
      _this.key = _this.key + 1;
    }, 3000);
    //eventi legati a cordova, partono solo al deviceready che viene scatenato da cordova quanto la app è pronta
    document.addEventListener(
      "deviceready",
      () => {
        setTimeout(function() {
          if (window.innerWidth < 600) {
            screen.orientation.lock("portrait");
          }
        }, 1500);

        //save last start time
        _this.updateLastStartTime();

        if (this.isCordova) {
          window.open = window.cordova.InAppBrowser.open;
        }

        document.addEventListener("resume", _this.onResume, false);

        //TODO: cambiare gestione da config.js alla router clicked
        window.IonicDeeplink.route(
          global.config.deeplinks,
          function(match) {
            _this.resolveRoute(match.$link);
          },
          function(nomatch) {
            console.log("NOMATCH :::: ", nomatch);
            _this.resolveRoute(nomatch.$link);
          }
        );

        this.onAppTrackingTransparency();

        //con x secondi controllo se c'è una versione più recente
        this.checkVersion();

        this.isPlatformIos();

        // Plugin Keyboard
        window.addEventListener("keyboardDidHide", () => {
          // Describe your logic which will be run each time keyboard is closed.
          console.log("keyboardDidHide");
          _this.keyboardOpen = false;
        });
        window.addEventListener("keyboardDidShow", event => {
          // Describe your logic which will be run each time when keyboard is about to be shown.
          console.log(event.keyboardHeight);
          console.log("keyboardDidShow");
          _this.keyboardOpen = true;
        });
        window.addEventListener("keyboardWillShow", event => {
          // Describe your logic which will be run each time when keyboard is about to be shown.
          console.log(event.keyboardHeight);
          console.log("keyboardWillShow");
          //iOS fix
          _this.keyboardOpen = true;
        });
        window.addEventListener("keyboardWillHide", () => {
          // Describe your logic which will be run each time when keyboard is about to be closed.
          console.log("keyboardWillHide");
          //iOS fix

          _this.keyboardOpen = false;
        });
        window.addEventListener(
          "CookiebotOnAccept",
          function() {
            _this.attValue = true;
          },
          false
        );

        window.addEventListener(
          "CookiebotOnDecline",
          function() {
            _this.attValue = false;
          },
          false
        );

        window.addEventListener("CookiebotOnLoad", function() {}, false);

        window.addEventListener(
          "CookiebotOnDialogDisplay",
          function() {
            // eslint-disable-next-line no-undef
            var hasResponded = Cookiebot.consented || Cookiebot.declined;
            if (
              window.cordova &&
              window.cordova.platformId.toLowerCase() == "ios" &&
              window.plugins.impacTracking &&
              !_this.attResponse
            ) {
              if (_this.attValue) {
                console.log("Ho cliccato su consenti");
                // Ho cliccato su "Consenti"
                // eslint-disable-next-line no-undef
                var stats = Cookiebot.consent.statistics;
                // eslint-disable-next-line no-undef
                var mrkg = Cookiebot.consent.marketing;
                // eslint-disable-next-line no-undef
                var pref = Cookiebot.consent.preferences;
                if (!hasResponded) {
                  console.log("Not responded");
                  // eslint-disable-next-line no-undef
                  Cookiebot.submitCustomConsent(true, true, true);
                  _this.attResponse = true;
                  // eslint-disable-next-line no-undef
                  Cookiebot.hide();
                } else {
                  console.log("Has responded");
                  // eslint-disable-next-line no-undef
                  Cookiebot.submitCustomConsent(pref, stats, mrkg);
                  _this.attResponse = true;
                }
              } else {
                console.log("Ho cliccato su non consentire");
                //Ho cliccato su "non consentire"
                // eslint-disable-next-line no-undef
                var declined = Cookiebot.declined;
                // eslint-disable-next-line no-undef
                Cookiebot.withdraw();
                _this.attResponse = true;
                if (!declined) {
                  console.log("First time");
                  //first time
                  // eslint-disable-next-line no-undef
                  Cookiebot.hide();
                }
              }
            }
          },
          false
        );
      },
      false
    );
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    global.EventBus.$off("isNotLoggedIn");
    global.EventBus.$off("restart");
    global.EventBus.$off("resetFilters");
    window.removeEventListener("scroll", this.onScroll);
  },
  watch: {
    $route(to) {
      this.load404 = false;
      if (to.meta.load404) {
        this.load404 = to.meta.load404;
      }
    },
    deliveryServiceId() {
      this.getDeliveryFee();
    },
    isAuthenticated(val, oldVal) {
      if (val !== oldVal && val === true) {
        this.checkMissingDisclaimer();
      }
    }
  }
};
</script>
