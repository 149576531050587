<template>
  <v-container class="fill-height default--text px-3">
    <v-layout fill-height column mt-5>
      <div class="d-flex justify-center">
        <v-img
          class="logo-modal"
          src="/img_layout/modal-logo.png"
          alt="Logo tosano"
          contain
          height="80px"
          width="80px"
        >
        </v-img>
      </div>
      <h3
        class="font-weight-bold text-center"
        v-if="category && category.metaData && category.metaData.category_info"
      >
        {{ category.metaData.category_info.TITLE }}
      </h3>

      <!-- Blocco header -->
      <v-container
        :fluid="isFluid"
        class="header mb-3"
        v-if="headerProposals.length > 0"
      >
        <div class="mt-5">
          <component
            :is="headerMode"
            :title="headerTitle"
            :proposals="headerProposals"
            :autoplay="true"
            paginationClass="pagination-header-address"
            cols="1"
            sm="auto"
            md="auto"
            lg="auto"
          />
        </div>
      </v-container>

      <v-row
        no-gutters
        v-if="deliveryServices.length > 1"
        justify="space-between"
        align="center"
        :style="{ height: $vuetify.breakpoint.smAndDown ? '100px' : '120px' }"
        class="mt-5 mb-3 tabs"
      >
        <v-col
          :cols="deliveryServiceButtonsLength"
          class="delivery-service-tab d-flex flex-column align-center justify-center flex-grow-1 h-100 rounded-md pa-3"
          v-for="(service, idx) in deliveryServices"
          :key="service.deliveryServiceId"
          :class="
            service.deliveryServiceId === selectedDeliveryServiceId
              ? 'selected'
              : ''
          "
          @click="fetchAddresses(service, idx)"
          :style="{ 'max-width': deliveryServiceButtonsWidth }"
        >
          <v-img
            class="delivery-service-tab-img"
            :src="getServiceParams(service.deliveryServiceId).icon"
            :alt="getServiceParams(service.deliveryServiceId).name"
            width="100"
            contain
          >
          </v-img>
          <span
            class="delivery-service-name white--text primary font-weight-bold text-uppercase"
            >{{ getServiceParams(service.deliveryServiceId).alias }}</span
          >
        </v-col>
      </v-row>
      <!-- delivery service descr -->
      <div
        class="minimum-shopping font-weight-bold text-center mb-3"
        v-if="getDeliveryServicesDescr"
        v-html="getDeliveryServicesDescr"
      ></div>
      <!-- ebsn description -->
      <div
        class="text-center text-body-2"
        v-html="category.metaData.category_info.DESCRIPTION"
        v-if="
          category &&
            category.metaData &&
            category.metaData.category_info &&
            category.metaData.category_info.DESCRIPTION
        "
      ></div>
      <v-card :loading="loading" flat>
        <v-row class="mb-2">
          <v-col cols="12">
            <v-text-field
              color="secondary"
              class="white"
              background-color="white"
              hide-details
              v-model="searchText"
              clearable
              outlined
              dense
              :label="
                $t(
                  'navbar.service.' + selectedDeliveryServiceId + '.searchLabel'
                )
              "
            >
              <v-icon slot="append" color="red">
                $search
              </v-icon>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="deliveryService.deliveryServiceId === 2">
          <v-col cols="12" sm="4">
            <v-btn
              color="secondary"
              depressed
              block
              :to="{ name: 'AddressEdit' }"
              @click="$emit('submit', false)"
            >
              <v-icon x-small class="mr-2">$plus</v-icon>INSERISCI NUOVO
              INDIRIZZO
            </v-btn>
          </v-col>
          <v-col cols="12" sm="4">
            <v-btn
              color="secondary"
              outlined
              depressed
              block
              @click="subscribeSteamboat('XH1V7')"
            >
              <v-icon small class="mr-2">$boat</v-icon>Consegna al vaporetto
            </v-btn>
          </v-col>
        </v-row>
        <v-list dense class="address-list">
          <v-list-item-group v-model="shippingAddress" color="primary">
            <v-list-item
              two-line
              v-for="address in filteredAddresses"
              :key="address.addressId"
              @click="setAddress(address)"
            >
              <v-list-item-content>
                <v-row
                  class="d-flex flex-nowrap"
                  no-gutters
                  justify="space-between"
                >
                  <v-icon class="mr-2">
                    {{ address.selected ? "$radioOn" : "$radioOff" }}
                  </v-icon>
                  <div>
                    <div
                      v-if="
                        address.addressClass && address.addressClass.length > 0
                      "
                      class="d-flex mb-1"
                    >
                      <AddressClass
                        class="mr-3"
                        v-for="addressClass in address.addressClass"
                        :key="addressClass['address-class-id']"
                        :addressClass="addressClass"
                      />
                    </div>
                    <v-list-item-title class="font-weight-bold text-uppercase">
                      {{ address.addressName }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{
                        $t(
                          "navbar.address." + address.addressTypeId + ".list",
                          address
                        )
                      }}
                    </v-list-item-subtitle>
                  </div>
                  <v-spacer />
                </v-row>
              </v-list-item-content>
              <v-list-item-action class="flex-row align-center">
                <v-tooltip
                  left
                  v-if="
                    address.addressId == cart.user.defaultStoreAddressId ||
                      address.addressId == cart.user.defaultDeliveryAddressId
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-img
                      width="32"
                      v-bind="attrs"
                      v-on="on"
                      src="/img_layout/address/favourite-address.svg"
                    ></v-img>
                  </template>
                  <span>{{
                    address.addressType == "home"
                      ? "Indirizzo preferito"
                      : "Negozio preferito"
                  }}</span>
                </v-tooltip>
                <v-tooltip left v-if="address.addressType == 'work'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">$boat</v-icon>
                  </template>
                  <span>{{
                    $t("navbar.service.tooltip.deliveryType.boatDelivery")
                  }}</span>
                </v-tooltip>
                <v-btn
                  class="no-border"
                  icon
                  @click.stop="edit(address)"
                  v-if="address.addressType == 'home'"
                >
                  <v-icon small>$edit</v-icon>
                </v-btn>
                <v-btn
                  class="no-border"
                  icon
                  @click.stop="remove(address)"
                  v-if="address.addressType == 'home'"
                >
                  <v-icon small>$delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>

      <!-- blocco footer -->
      <v-container
        :fluid="isFluid"
        class="footer footer-address"
        v-if="footerProposals.length > 0"
      >
        <component
          :is="footerMode"
          :title="footerTitle"
          :proposals="footerProposals"
          :autoplay="true"
          paginationClass="pagination-footer-address"
          cols="auto"
          sm="auto"
          md="auto"
          lg="auto"
        />
      </v-container>
    </v-layout>
  </v-container>
</template>
<style scoped lang="scss">
.tabs {
  .v-icon {
    font-size: 60px;
    height: 80px;
  }
  .delivery-service-tab {
    border: 3px solid var(--v-secondary-lighten5);
    .delivery-service-tab-img {
      height: 90px;
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
      .delivery-service-name {
        font-size: 9px;
        line-height: 1.1;
      }
      .delivery-service-tab-img {
        height: 60px;
      }
    }
  }
  .selected {
    color: $white !important;
    background: var(--v-secondary-lighten5);
    .v-icon,
    span {
      color: $white !important;
    }
  }
}
.minimum-shopping {
  border-radius: $border-radius-root;
  border: 2px solid var(--v-primary-base);
  padding: 5px;
}
.address-list {
  .v-list-item {
    border-bottom: 2px solid var(--v-grey-lighten2);
  }
  .v-list-item__subtitle {
    white-space: pre-line;
  }
  .v-list-item--two-line {
    min-height: 50px;
  }
  .v-list-item--active::before {
    background-color: transparent;
  }
  .v-list-item--active {
    border-radius: $border-radius-root;
    background-color: var(--v-secondary-lighten5);
    color: $text-color;
  }
}
.address-list {
  overflow-y: auto;
  height: 315px;
}
.v-btn.no-border {
  border: none;
}
</style>
<script>
import AddressClass from "@/components/delivery/AddressClass.vue";

import StoreService from "~/service/storeService";
import DeliveryService from "~/service/deliveryService";
import CategoryService from "~/service/categoryService";

import { mapState } from "vuex";

import { mapProposalComponent } from "~/service/ebsn.js";

export default {
  name: "AddressSelector",
  components: {
    ProposalProductListSlider: () =>
      import("@/components/proposal/ProposalProductListSlider.vue"),
    ProposalProductGrid: () =>
      import("@/components/proposal/ProposalProductGrid.vue"),
    ProposalSlider: () => import("@/components/proposal/ProposalSlider.vue"),
    ProposalGrid: () => import("@/components/proposal/ProposalGrid.vue"),
    AddressClass
  },
  data() {
    return {
      store: {},
      addresses: [],
      loading: null,
      shippingAddress: null,
      deliveryService: null,
      tab: 1,
      searchText: null,
      category: {}
    };
  },
  computed: {
    ...mapProposalComponent({
      header: "header",
      footer: "footer"
    }),
    deliveryServiceButtonsLength() {
      return 12 / this.deliveryServices.length;
    },
    deliveryServiceButtonsWidth() {
      return 100 / this.deliveryServices.length - 1 + "%";
    },
    filteredAddresses() {
      if (this.searchText) {
        return this.addresses.filter(address => {
          return (
            address.addressName
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) > -1 ||
            address.address1
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) > -1 ||
            address.city.toLowerCase().indexOf(this.searchText.toLowerCase()) >
              -1
          );
        });
      } else {
        return this.addresses;
      }
    },
    ...mapState({
      cart: ({ cart }) => cart.cart,
      selectedService: ({ cart }) => cart.selectedService
    }),
    selectedDeliveryServiceId() {
      return this.deliveryService ? this.deliveryService.deliveryServiceId : 0;
    },
    getDeliveryServicesDescr() {
      for (const deliveryService in this.deliveryServices) {
        if (
          this.deliveryServices[deliveryService].deliveryServiceId ==
          this.selectedDeliveryServiceId
        ) {
          return this.deliveryServices[deliveryService].descr;
        }
      }
      return false;
    },
    deliveryServices() {
      if (this.store && this.store.deliveryServices) {
        return this.store.deliveryServices.filter(button => {
          return button.showTab;
        });
      }
      return [];
    }
  },
  methods: {
    addressClass(address) {
      if (address && address.addressClass && address.addressClass.length) {
        return address.addressClass;
      }
    },
    async remove(address) {
      let _this = this;
      const res = await _this.$dialog.confirm({
        text: global.vm.$t("message.removeAddress")
      });
      if (res) {
        let response = await DeliveryService.removeAddress(address.addressId);
        _this.addresses = response.addresses;
      }
    },
    edit(address) {
      let _this = this;
      // _this.address = address;
      _this.$router.push({
        path: "/profile/addresses/manage/" + address.addressId
      });
      _this.$emit("submit", true);
    },
    async fetchStore() {
      let _this = this;
      let store = await StoreService.getStoreById(_this.cart.store.storeId);
      _this.store = store;

      _this.store.deliveryServices.find(deliveryService => {
        if (
          deliveryService.deliveryServiceId ==
          _this.cart.shippingAddress.deliveryServiceId
        ) {
          _this.deliveryService = deliveryService;
        }
      });
    },
    async fetchAddresses(deliveryService, idx) {
      let _this = this;
      if (typeof idx !== "undefined") {
        _this.tab = idx;
      }
      _this.loading = true;
      _this.searchText = null;
      let deliveryServiceId =
        deliveryService.deliveryServiceId || deliveryService;
      _this.deliveryService = deliveryService;
      _this.addresses = await StoreService.getAddressesByDeliveryService(
        deliveryServiceId
      );
      _this.addresses.find((address, index) => {
        if (address.addressId == _this.cart.shippingAddress.addressId) {
          _this.shippingAddress = index;
        }
      });
      _this.loading = false;
    },
    async setAddress(address) {
      let _this = this;
      this.loading = true;
      let data = await this.$store.dispatch("cart/setAddress", {
        addressType: address.addressType,
        addressId: address.addressId
      });
      this.loading = false;
      if (data) {
        _this.$emit("submit", true);
        setTimeout(function() {
          _this.$router.push({ name: "Home" });
        }, 200);
      }
    },
    async subscribeSteamboat(companyCode) {
      this.loading = true;
      await DeliveryService.subscribeCompany(companyCode);
      this.fetchAddresses();
      // AnalyticsService.trackEvent("Delivery", "Subscribe Company", companyCode);
      this.loading = false;
    },
    getServiceParams(serviceId) {
      return this.$t("navbar.service." + serviceId);
    },
    async fetchCategory() {
      try {
        this.category = await CategoryService.getCategoryBySlug(
          "service-selector"
        );
      } catch (e) {
        console.log(e);
      }
    }
  },
  created() {
    this.fetchStore();
    this.fetchAddresses(this.cart.shippingAddress.deliveryServiceId);
    this.fetchCategory();
  }
};
</script>
