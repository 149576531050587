<template>
  <div v-if="promoObj" class="promo-box" :class="[promoObj.promoClasses]">
    <div class="promo-cnt d-flex align-center px-2">
      <!-- <div
        v-if="promoObj.promoHeader"
        v-html="promoObj.promoHeader"
        class="promo-header text-uppercase"
      ></div> -->
      <img
        v-if="promoObj.promoHeader"
        :src="promoObj.promoHeader"
        alt="promo img"
        class="promo-header text-uppercase"
      />
      <div
        v-if="promoObj.promoText"
        v-html="promoObj.promoText"
        class="promo-body text-uppercase"
      ></div>
      <div
        v-if="promoObj.promoFooter"
        v-html="promoObj.promoFooter"
        class="promo-footer text-uppercase"
      ></div>
    </div>
  </div>
</template>
<style lang="scss">
.product-detail {
  .promo-box {
    border-top-left-radius: $border-radius-root;
    border-top-right-radius: $border-radius-root;
  }
}

.promo-box {
  background-color: $promo;
  color: $white;
  position: relative;
  width: $promo-box-width;
  height: $promo-box-height;

  font-size: 12px;
  .promo-header {
    height: 18px;
  }
  img.promo-header {
    height: 14px;
    width: 12px;
    margin-right: 4px;
    margin-top: -2px;
  }
  .promo-cnt {
    padding-top: 2px;
  }
  .promo-body {
    font-weight: bold;
    text-align: center;
    white-space: nowrap;
    br {
      content: " ";
    }
  }
  &.red_box {
    background-color: var(--v-primary-lighten1);
    color: $white;
  }
  &.yellow_box {
    background-color: var(--v-yellow-base);
    color: var(--v-primary-lighten1);
  }
  &.green_box {
    background-color: #50d708;
  }
  &.orange_box {
    background-color: #f58a09;
  }
  &.dark_green_box {
    background-color: #35ad04;
  }
  &.dark_orange_box {
    background-color: #f05738;
  }
  &.light_blue_box {
    background-color: #7baeff;
  }
  &.brown_box {
    background-color: #6a4a3a;
    color: var(--v-yellow-base);
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    height: $promo-box-height-mobile;
  }
}
</style>
<script>
import get from "lodash/get";

export default {
  name: "ProductPromo",
  props: {
    product: { type: Object, required: true }
  },
  computed: {
    productClassPromo() {
      let promoClasses = this.product.productClasses.filter(
        p => p.productClassTypeId == 2 && p.productClassGroupId == 1
      );
      return promoClasses;
    },
    promoObj() {
      //  promo sottocosto
      let underPriceClass = get(this.product, "productInfos.UNDERPRICE");
      if (underPriceClass) {
        let classes = "is-underprice red_box";
        this.$emit("setPromoClass", classes);
        return {
          promoClasses: classes,
          promoText: this.$t("promo.underPrice")
        };
      }

      // warehousePromo
      let whPromoCssClass = get(this.product, "warehousePromo.view.cssClass");
      if (whPromoCssClass) {
        let whPromoHeader = get(this.product, "warehousePromo.view.header", "");
        // whPromoHeader = "<img src='/img_layout/promo/t.png' alt='t promo' />";
        let whPromoText = get(this.product, "warehousePromo.view.body", "");
        let whPromoFooter = get(this.product, "warehousePromo.view.footer", "");
        let classes = "has-promo " + whPromoCssClass;
        this.$emit("setPromoClass", classes);
        return {
          promoClasses: classes,
          promoHeader: whPromoHeader,
          promoText: whPromoText,
          promoFooter: whPromoFooter
        };
      }

      // "promo" from product class
      if (this.productClassPromo && this.productClassPromo.length > 0) {
        let classes = this.productClassPromo[0].description;
        let img = get(this, "productClassPromo[0].iconSource");
        this.$emit("setPromoClass", classes);
        return {
          promoClasses: classes,
          promoText: this.productClassPromo[0].name,
          promoHeader: img
        };
      }

      // "promo" from newProduct
      if (this.product.newProduct) {
        let classes = "new-product is-new yellow_box";
        this.$emit("setPromoClass", classes);
        return {
          promoClasses: classes,
          promoText: this.$t("promo.newProduct")
        };
      }

      // default no promo
      return null;
    }
  }
};
</script>
