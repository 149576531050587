<template>
  <div
    class="registration-module-disclaimer-container"
    @click="clicked"
    v-if="registrationModules"
  >
    <div
      class="disclaimer-row mb-6"
      v-for="disclaimer in registrationModules"
      :key="disclaimer.registrationDisclaimerId"
    >
      <div
        class="disclaimer-title text-h5 font-weight-bold"
        v-html="disclaimer.disclaimerTitle"
      ></div>
      <router-link
        v-if="disclaimer.disclaimerUrl && disclaimer.disclaimerUrl"
        class="disclaimer-descr d-block"
        v-html="disclaimer.disclaimerContent"
        :to="disclaimer.disclaimerUrl"
        :aria-label="`Vai alla ${disclaimer.disclaimerUrl}`"
        :title="`Vai alla ${disclaimer.disclaimerUrl}`"
      >
      </router-link>
      <div
        v-else-if="disclaimer.disclaimerContent"
        class="disclaimer-descr"
        v-html="disclaimer.disclaimerContent"
      ></div>

      <div v-if="disclaimer.required == 'true'">
        <!-- {{ disclaimer }} -->
        <v-checkbox
          :rules="checkboxRules"
          v-model="disclaimer.value"
          class="disclaimer-checkbox"
          :color="mergedOptions.color"
          required
          false-value="false"
          true-value="true"
          @change="disclaimerChanged(disclaimer)"
        >
          <template v-slot:label>
            <span
              class="disclaimer-label disclaimer-checkbox-label"
              v-html="
                disclaimer.disclaimerLabel
                  ? disclaimer.disclaimerLabel
                  : $t('disclaimer.checkboxLabel')
              "
            ></span>
          </template>
        </v-checkbox>
      </div>
      <div v-else>
        <!-- {{ disclaimer }} -->
        <v-radio-group
          dense
          v-model="disclaimer.value"
          row
          :rules="[requiredRules()]"
          @change="disclaimerChanged(disclaimer)"
        >
          <v-radio :color="mergedOptions.color" :value="'true'"
            ><template v-slot:label>
              <span class="disclaimer-label disclaimer-radio-label">{{
                $t("disclaimer.radioLabelYes")
              }}</span>
            </template></v-radio
          >
          <v-radio :color="mergedOptions.color" :value="'false'">
            <template v-slot:label>
              <span class="disclaimer-label disclaimer-radio-label">{{
                $t("disclaimer.radioLabelNo")
              }}</span>
            </template>
          </v-radio>
        </v-radio-group>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.registration-module-disclaimer-container {
  .v-input--selection-controls {
    margin-top: 0px;
  }
}
</style>
<script>
import clickHandler from "~/mixins/clickHandler";

// import UserService from "@/commons/service/userService";

import merge from "lodash/merge";

import { requiredValue, isCheckboxTrue } from "~/validator/validationRules";

export default {
  name: "RegistrationModuleDisclaimer",
  props: {
    disclaimerList: { type: Array, required: true },
    options: { type: Object, default: () => {} }
  },
  mixins: [clickHandler],
  data() {
    return {
      registrationModules: null,
      requiredRules: requiredValue,
      checkboxRules: [
        requiredValue("È obbligatorio accettare questo consenso per procedere"),
        isCheckboxTrue("È obbligatorio accettare questo consenso per procedere")
      ]
    };
  },
  computed: {
    mergedOptions() {
      return merge(
        {
          color: "primary"
        },
        this.options
      );
    }
  },
  methods: {
    disclaimerChanged() {
      this.$emit("disclaimerChanged", this.registrationModules);
    }
  },
  created() {
    this.registrationModules = this.disclaimerList;
  }
};
</script>
