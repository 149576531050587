<template>
  <v-container class="mt-5">
    <div
      class="text-h4 line-height-1 d-block font-weight-bold text-center secondary--text my-5"
    >
      {{ $t("message.checkForMissingDisclaimer") }}
    </div>
    <v-form
      ref="disclaimerform"
      v-model="isDisclaimerFormValid"
      lazy-validation
    >
      <RegistrationModuleDisclaimer
        v-if="registrationModules"
        :disclaimerList="registrationModules"
        :options="disclaimerOptions"
        @disclaimerChanged="disclaimerChanged"
      />
      <div class="d-flex justify-center justify-md-end mt-3 mt-sm-6">
        <v-btn
          color="secondary"
          large
          elevation="0"
          min-width="250"
          :block="$vuetify.breakpoint.xs"
          :disabled="!isDisclaimerFormValid"
          @click="updateDisclaimers()"
          :loading="loadingDisclaimerChange"
        >
          {{ $t("profile.updateDiscalimer") }}
        </v-btn>
      </div>
    </v-form>
  </v-container>
</template>
<script>
import RegistrationModuleDisclaimer from "@/commons/components/disclaimer/RegistrationModuleDisclaimer.vue";

import UserService from "~/service/userService";

export default {
  name: "CheckDisclaimerDialog",
  components: {
    RegistrationModuleDisclaimer
  },
  data() {
    return {
      isDisclaimerFormValid: false,
      loadingDisclaimerChange: false,
      disclaimerList: null,
      userDisclaimer: null,
      registrationModules: null,
      disclaimerToChange: null,
      disclaimerOptions: {
        color: "secondary"
      }
    };
  },
  methods: {
    onClick() {
      this.$emit("submit", true);
    },
    setUserDisclaimerValue(disclaimerId, value) {
      let disclaimer = this.userDisclaimer.registrationModuleDisclaimers.find(
        item =>
          item?.registrationDisclaimer?.registrationDisclaimerId ===
          disclaimerId
      );
      if (disclaimer) {
        disclaimer.value = value;
      }
    },
    async updateDisclaimers() {
      let vm = this;
      try {
        console.log("updateDisclaimers", vm.disclaimerToChange);
        vm.loadingDisclaimersChange = true;
        if (vm.$refs.disclaimerform.validate()) {
          if (vm.disclaimerToChange && vm.disclaimerToChange !== null) {
            let registrationModulesParam = vm.disclaimerToChange.map(
              disclaimer => {
                let obj = {
                  registrationDisclaimer: {
                    registrationDisclaimerId:
                      disclaimer.registrationDisclaimerId
                  },
                  value: disclaimer.value
                };
                if (disclaimer.registrationModuleDisclaimerId) {
                  obj.registrationModuleDisclaimerId =
                    disclaimer.registrationModuleDisclaimerId;
                }
                return obj;
              }
            );

            if (vm.userDisclaimer && vm.userDisclaimer.registrationModules) {
              vm.userDisclaimer.registrationModules[0].registrationModuleDisclaimers = registrationModulesParam;
            } else {
              vm.userDisclaimer = {
                registrationModules: [
                  {
                    registrationModuleAction: {
                      registrationModuleActionId: 1,
                      name: "Inserimento"
                    },
                    registrationModuleType: {
                      registrationModuleTypeId: 2,
                      name: "REG_WEB",
                      description: ""
                    },
                    addDate: new Date().toISOString(),
                    registrationModuleDisclaimers: registrationModulesParam
                  }
                ]
              };
            }
          }
          let result = await UserService.updateUserDisclaimers(
            vm.userDisclaimer.registrationModules[0]
          );
          if (
            result &&
            result.registrationModules &&
            result.registrationModules.length > 0
          ) {
            this.userDisclaimer = result;
          }

          vm.loadingDisclaimersChange = false;
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.$emit("submit", true);
        vm.loadingDisclaimersChange = false;
      }
    },
    disclaimerChanged(userDisclaimer) {
      console.log("disclaimerChanged", userDisclaimer);
      this.disclaimerToChange = userDisclaimer;
    },
    async loadDisclaimer() {
      let storeId = 5;
      let registrationModuleTypeId = 2;
      await this.getDisclaimerList(registrationModuleTypeId, storeId);
      await this.getUserDisclaimer(registrationModuleTypeId, storeId);
    },
    async getDisclaimerList() {
      let vm = this;
      let result = await UserService.getRegistrationDisclaimerList(2, 5);
      if (result && result.registrationDisclaimers) {
        vm.disclaimerList = result.registrationDisclaimers;
      }
    },
    async getUserDisclaimer(registrationModuleTypeId, storeId) {
      let result = await UserService.getUserDisclaimerListByType(
        registrationModuleTypeId,
        storeId
      );
      if (
        result &&
        result.registrationModules &&
        result.registrationModules.length > 0 &&
        result.registrationModules[0].registrationModuleDisclaimers
      ) {
        this.userDisclaimer = result;
        result.registrationModules[0].registrationModuleDisclaimers.forEach(
          disclaimer => {
            let disclaimerFromList = this.disclaimerList.find(
              item =>
                item.registrationDisclaimerId ===
                disclaimer.registrationDisclaimer.registrationDisclaimerId
            );
            if (disclaimerFromList) {
              disclaimerFromList.value = disclaimer.value;
              disclaimerFromList.registrationModuleDisclaimerId =
                disclaimer.registrationModuleDisclaimerId;
            }
          }
        );
      }
      console.log("this.userDisclaimer", this.userDisclaimer);
      this.registrationModules = this.disclaimerList;
    }
  },
  async mounted() {
    await this.loadDisclaimer();
  }
};
</script>
