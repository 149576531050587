import axios from "~/plugins/axios";
// const qs = require("qs");

export default {
  getUserDetail() {
    let url = "/ebsn/api/registration/user_detail";
    return axios
      .get(url)
      .then(response => response.data.data.user)
      .catch(err => err);
  },
  updateUserDetail(user) {
    let url = "/ebsn/api/registration/update_user_detail";
    return axios
      .post(url, user)
      .then(response => response.data.data.user)
      .catch(err => err);
  },
  updatePersonInfo(personInfoTypeId, value) {
    let url = "/ebsn/api/registration/update_person_info";
    return axios
      .post(url, { personInfoTypeId, value })
      .then(response => response.data.data.user)
      .catch(err => err);
  },
  resetPassword(email, token, password, login) {
    let url = "/ebsn/api/registration/password_reset";
    return axios
      .get(url, {
        params: {
          e_mail: email,
          reset_token: token,
          password: password,
          login: login
        }
      })
      .then(data => {
        if (data.data.response.status == 0) {
          return data.data;
        } else {
          Promise.reject(data);
        }
      });
  },
  updateBillingAddress(user) {
    let url = "/ebsn/api/registration/update_billing_address";
    return axios
      .post(url, user)
      .then(response => {
        console.log(response);
        return response.data.data.user;
      })
      .catch(err => {
        console.log(err);
        return err;
      });
  },
  getResetPasswordToken(email, fiscalCode, login) {
    let url = "/ebsn/api/registration/password_reset_token_request";
    return axios
      .get(url, {
        params: { e_mail: email, fiscal_code: fiscalCode, login: login }
      })
      .then(data => {
        if (data.data.response.status == 0) {
          return data.data;
        } else {
          Promise.reject(data);
        }
      });
  },
  resetPasswordTokenCheck(email, token, login) {
    let url = "/ebsn/api/registration/password_reset_token_check";
    return axios
      .get(url, {
        params: { e_mail: email, reset_token: token, login: login }
      })
      .then(data => {
        return data.data;
      });
  },
  updateEmail(token) {
    let url = "/ebsn/api/registration/update_email";
    return axios.get(url, { params: { token: token } }).then(data => {
      return data.data;
    });
  },
  updateEmailRequest(newEmail) {
    let url = "/ebsn/api/registration/update_email_request";
    return axios.get(url, { params: { new_email: newEmail } }).then(data => {
      return data.data;
    });
  },
  updateUsername(username) {
    let url = "/ebsn/api/registration/update_username";
    let params = { username: username };

    return axios
      .post(url, null, {
        params: params,
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(data => {
        return data.data;
      });
  },
  updatePassword(oldPassword, newPassword) {
    let url = "/ebsn/api/registration/update_password";
    return axios
      .get(url, {
        params: { old_password: oldPassword, new_password: newPassword }
      })
      .then(response => {
        return response.data;
      })
      .catch(err => err);
  },
  registrationUser(user) {
    let url = "/ebsn/api/registration/register_user";
    return axios
      .post(url, user)
      .then(response => {
        return response.data;
      })
      .catch(err => err);
  },
  confirmRegistration(email, token) {
    let url = "/ebsn/api/registration/registration_confirm";
    return axios
      .get(url, {
        params: {
          e_mail: email,
          reg_control_code: token
        }
      })
      .then(response => {
        return response.data.response;
      });
  },
  checkRemoteVersion() {
    return axios.get("/version.json");
  },
  registrationResendEmail(email) {
    let url = "/ebsn/api/registration/registration_resend_email";
    return axios
      .get(url, {
        params: {
          e_mail: email
        }
      })
      .then(response => {
        return response.data;
      })
      .catch(err => err);
  },
  listGiftCertificate(type, show_history = true) {
    if (type == "manual") {
      let url = "/ebsn/api/giftcertificate/list-manual-assigned";
      return axios.get(url, { params: { show_history } }).then(data => {
        return data.data.data.giftCertificates || [];
      });
    } else {
      let url = "/ebsn/api/giftcertificate/list";
      return axios.get(url, { params: { show_history } }).then(data => {
        return data.data.data.giftCertificates || [];
      });
    }
  },
  anonymizeAccount() {
    return axios
      .get("/ebsn/api/registration/user_anonymize")
      .then(data => {
        return data.data;
      })
      .catch(err => err);
  },
  anonymizeAccountTokenRequest() {
    return axios
      .get("/ebsn/api/registration/anonymize_token_request")
      .then(data => {
        return data.data;
      })
      .catch(err => err);
  },
  anonymizeAccountTokenCheck(login, token) {
    return axios
      .get("/ebsn/api/registration/anonymize_token_check", {
        params: {
          login: login,
          token: token
        }
      })
      .then(data => {
        return data.data;
      })
      .catch(err => err);
  },
  updateContact(user) {
    let url = "/ebsn/api/registration/update_contact";
    return axios
      .post(url, user)
      .then(response => response.data.data.user)
      .catch(err => err);
  },
  getFiscalsPersonsList() {
    return axios
      .get("/ebsn/api/user-person-fiscal/list")
      .then(response => {
        return response.data.data;
      })
      .catch(err => err);
  },
  insertPersonFiscal(personFiscal) {
    let url = "/ebsn/api/user-person-fiscal/insert-person-fiscal";
    return axios
      .post(url, personFiscal)
      .then(response => {
       
        return response.data.data;
      })
      .catch(err => {
       
        return err;
      });
  },
  insertBillingAddress(user) {
    let url = "/ebsn/api/user-person-fiscal/insert-billing-address";
    return axios
      .post(url, user)
      .then(response => {
       
        return response.data.data.user;
      })
      .catch(err => {
        console.log(err);
        return err;
      });
  },
  deletePersonFiscal(personFiscalId) {
      let url = "/ebsn/api/user-person-fiscal/delete-person-fiscal";
      return axios.get(url, { params: { person_fiscal_id: personFiscalId } }).then(data => {
        return data.data;
      });
  },
  getRegistrationDisclaimerList(registrationModuleTypeId = 2, storeId = 5) {
    // get disclaimer list for registration
    let url = "/ebsn/api/registration-disclaimer/list";
    return axios
      .get(url, {
        params: {
          store_id: storeId,
          registration_module_type_id: registrationModuleTypeId
        }
      })
      .then(response => {
        return response.data.data;
      })
      .catch(err => err);
  },
  getUserDisclaimerListByType(registrationModuleTypeId = 2, storeId = 5) {
    // get user disclaimer for logged user
    let url = "/ebsn/api/user-registration-disclaimer/list-by-type";
    return axios
      .get(url, {
        params: {
          registration_module_type_id: registrationModuleTypeId,
          store_id: storeId
        }
      })
      .then(response => {
        return response.data.data;
      })
      .catch(err => err);
  },
  checkUserDisclaimer(registrationModuleTypeId = 2) {
    // check if some disclaimer is expired
    let url = "/ebsn/api/user-registration-disclaimer/check-disclaimer";
    return axios
      .get(url, {
        params: {
          registration_module_type_id: registrationModuleTypeId
        }
      })
      .then(response => {
        return response.data.data;
      })
      .catch(err => err);
  },
  updateUserDisclaimers(registrationModules) {
    let url = "/ebsn/api/user-registration-disclaimer/update-disclaimer";
    return axios
      .post(url, registrationModules)
      .then(response => response.data.data.user)
      .catch(err => err);
  }
};
