var render = function render(_c,_vm){return _c('div',{staticClass:"text-caption primary--text text-lighten-2 mt-2"},[_c('i18n',{attrs:{"path":"checkout.acceptText","tag":"span"},scopedSlots:_vm._u([{key:"link1",fn:function(){return [_c('router-link',{attrs:{"to":{
          name: 'Page',
          params: { pageName: 'condizioni-vendita' }
        }}},[_vm._v(" "+_vm._s(_vm.parent.$t("checkout.textLink1"))+" ")])]},proxy:true},{key:"link2",fn:function(){return [_c('router-link',{attrs:{"to":{
          name: 'Page',
          params: { pageName: 'privacy-policy' }
        }}},[_vm._v(" "+_vm._s(_vm.parent.$t("checkout.textLink2"))+" ")])]},proxy:true},{key:"link3",fn:function(){return [_c('router-link',{attrs:{"to":{
          name: 'Page',
          params: { pageName: 'cookie-policy' }
        }}},[_vm._v(" "+_vm._s(_vm.parent.$t("checkout.textLink3"))+" ")])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }