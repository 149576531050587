<template>
  <div v-if="deliveryFee && deliveryServiceId == 2" class="delivery-fee">
    <div class="delivery-fee-list accent lighten-3 rounded" v-if="showList">
      <div class="text-h3 warning--text font-weight-bold">
        {{ $t("deliveryFee.listTitle") }}
      </div>
      <div
        class="black--text text-caption"
        v-for="(deliveryFeeRow, index) in deliveryFee.deliveryFeeRows"
        :key="deliveryFeeRow.deliveryFeeRowId"
        v-html="deliveryFeeRow.shortDescr"
        :class="index == indexDeliveryFeeRange ? 'font-weight-bold' : ''"
      ></div>
      <div class="caution mt-3" v-html="$t('deliveryFee.caution')"></div>
    </div>

    <div
      v-else-if="deliveryFee.deliveryFeeRows.length > 0 && discountText"
      :class="optionClass"
    >
      <span class="accent--text font-weight-bold">{{ discountText }}</span>
      <v-progress-linear :value="ammountToReachDiscount"></v-progress-linear>
      <div
        v-for="deliveryFeeRow in this.deliveryFee.deliveryFeeRow"
        :key="deliveryFeeRow.deliveryFeeRowId"
        class="d-flex align-items"
      >
        <v-progress-linear
          :value="actualPercentaceToMaxGoal"
        ></v-progress-linear>
        <div class="pallino-colorato"></div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.delivery-fee-list,
.discount {
  padding: 12px;
  font-size: 12px;
}
.delivery-fee-list {
  .text-h3 {
    line-height: 1.45;
    margin-bottom: 6px;
  }
  .caution {
    font-size: 10px;
    color: var(--v-grey-darken1);
  }
}
.discount {
  margin: 3px 0;
  max-height: 40px;
}
.navbar-delivery-fee {
  font-size: 11px;
}
</style>
<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "DeliveryFee",
  props: {
    showList: { type: Boolean, default: true },
    deliveryFee: { type: Object, required: false },
    optionClass: { type: String, default: "discount accent lighten-3" }
  },
  data() {
    return {
      minimunAmmountToDiscount: 15,
      ammountToReachDiscount: 0
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      deliveryServiceId: "cart/getDeliveryServiceId"
    }),
    discountText() {
      if (this.deliveryFee) {
        for (let i = 0; i < this.deliveryFee.deliveryFeeRows.length; i++) {
          if (
            this.deliveryFee.deliveryFeeRows[i].range > this.cart.totalPrice
          ) {
            let amountToDiscount =
              this.deliveryFee.deliveryFeeRows[i].range - this.cart.totalPrice;
            if (amountToDiscount < this.minimunAmmountToDiscount) {
              // se meno di minimunAmmountToDiscount € non mostriamo
              this.$emit("changeShowingDeliveryFee", true);
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              this.ammountToReachDiscount =
                ((this.minimunAmmountToDiscount - amountToDiscount) * 100) /
                this.minimunAmmountToDiscount;
              return this.$t("deliveryFee.amountToDiscount", {
                amount: this.$n(amountToDiscount, "currency")
              });
            }
          }
        }
      }
      this.$emit("changeShowingDeliveryFee", false);
      return null;
    },
    // actualPercentaceToMaxGoal() {
    //   let perc = this.maxGrossTotalGoal
    //     ? (this.cart.totalPrice * 100) / this.maxGrossTotalGoal
    //     : 0;
    //   return perc;
    // },
    // maxGrossTotalGoal() {
    //   let goal = 0;
    //   if (this.deliveryFee?.deliveryFeeRows.length > 0) {
    //     goal =
    //       this.deliveryFee.deliveryFeeRows.length > 1
    //         ? this.deliveryFee.deliveryFeeRows[
    //             this.deliveryFee.deliveryFeeRows.length - 2
    //           ].range
    //         : this.deliveryFee.deliveryFeeRows[0].range;
    //   }
    //   return goal;
    // },
    indexDeliveryFeeRange() {
      if (this.deliveryFee) {
        for (let i = 0; i < this.deliveryFee.deliveryFeeRows.length; i++) {
          if (
            this.cart.totalPrice > this.deliveryFee.deliveryFeeRows[i].range &&
            !(
              this.deliveryFee.deliveryFeeRows[i + 1] &&
              this.cart.totalPrice >
                this.deliveryFee.deliveryFeeRows[i + 1].range
            )
          ) {
            console.log(
              this.deliveryFee.deliveryFeeRows[i].range,
              this.cart.totalPrice
            );
            return i + 1;
          }
        }
      }
      return 0;
    }
  }
};
</script>
