let routes = [
  {
    path: "/punti-vendita",
    name: "WarehouseList",
    meta: { breadCrumb: "Punti Vendita" },
    component: () => import("@/views/store/WarehouseList.vue")
  },
  {
    path: "/punti-vendita/:warehouseId",
    name: "WarehouseDetail",
    meta: { breadCrumb: "Punti Vendita" },
    component: () => import("@/views/store/WarehouseDetail.vue")
  },
  {
    path: "/warehouse-map",
    name: "WarehouseMap",
    meta: { breadCrumb: "Mappa" },
    component: () => import("@/components/wordpress/WarehouseMap.vue")
  },
  {
    path: "/warehouse-cards",
    name: "WarehouseCard",
    meta: { breadCrumb: "Mappa" },
    component: () => import("@/components/wordpress/WarehouseCards.vue")
  }
];

export default routes;
