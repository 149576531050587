<template>
  <div
    id="cartItemList"
    class="cart-item-list pb-0"
    :class="{ 'checkout-item-list': full }"
  >
    <v-subheader v-if="!full" class="text-caption mx-1 d-flex default--text">
      <CartItemCount :count="totalItems" />
      <v-spacer />
      <v-switch
        v-model="showSectors"
        label="Dividi per categoria"
        color="secondary"
      ></v-switch>
    </v-subheader>

    <div v-if="!showSectors" :class="{ 'cart-item-list-no-checkout': !full }">
      <div
        v-for="cartItem in cart.cartItems"
        :key="cartItem.cartItemId"
        class="align-center"
        :class="{ 'px-2': !full }"
      >
        <CartItem :item="cartItem" :full="full" />
      </div>
    </div>
    <v-expansion-panels v-else v-model="panel" accordion flat multiple>
      <v-expansion-panel
        v-for="([categoryId, group], index) in itemGroups"
        v-bind:key="categoryId"
      >
        <v-expansion-panel-header
          v-on:click.prevent="() => {}"
          class="text-caption secondary--text secondary lighten-5 px-3"
        >
          <div class="d-flex align-center">
            <span class="font-weight-bold mr-2">{{ group.name }}</span>
            <span>
              {{ `${$tc("lists.products", group.products.length)}` }}
            </span>
          </div>
          <template v-slot:actions>
            <v-icon color="secondary" x-small>
              {{ panel.includes(index) ? "$minus" : "$plus" }}
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <CartItem
            class="sectorItem"
            v-for="item in group.products"
            v-bind:key="item.itemId"
            :item="item"
            :full="full"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<style scoped lang="scss">
.v-expansion-panel {
  border-radius: 0;
  .v-expansion-panel-header {
    min-height: 20px;
    padding: 10px 24px;
  }
  .v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
    padding: 0px !important;
    .v-list-item {
      padding: 0 16px;
    }
  }
  ::v-deep.sectorItem a {
    padding-right: 0 !important;
  }
}
.cart-item {
  border-bottom: 1px solid $gray-border-color;
  &:last-child {
    border-bottom: none;
  }
}
</style>
<script>
import CartItem from "./CartItem";
import CartItemCount from "./CartItemCount";

import { mapActions, mapState } from "vuex";

// import map from "lodash/map";
// import groupBy from "lodash/groupBy";

import { forEachCartItem } from "~/service/ebsn";

export default {
  name: "CartItemList",
  components: { CartItem, CartItemCount },
  props: {
    full: { type: Boolean, default: false },
    showSectors: { type: Boolean, default: true }
  },
  data() {
    return {
      panel: []
    };
  },
  computed: {
    ...mapState({
      categories: ({ category }) => category.categoryTree,
      cart: ({ cart }) => cart.cart
    }),
    totalItems() {
      let total = 0;
      forEachCartItem(this.cart, function(item) {
        total += item.quantity > 0 ? item.quantity : 1;
      });
      return total;
    },
    orderComment: {
      get() {
        return this.cart.cartInfos.order_comment;
      },
      set(value) {
        console.log(value);
        //update on blur
      }
    },
    itemGroups() {
      let groups = new Map();
      if (this.cart.cartItems) {
        this.cart.cartItems.forEach(item => {
          let category = this.$store.getters["category/lookupCategory"](
            item.product.categoryId
          );
          if (category === null || category === undefined) {
            category = {
              categoryId: -1,
              name: "Senza categoria"
            };
          }
          if (groups.has(category.categoryId)) {
            groups.get(category.categoryId).products.push(item);
          } else {
            groups.set(category.categoryId, {
              name: category.name,
              products: [item]
            });
          }
        });
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.panel = Array.from(Array(groups.size).keys());

      return groups;
    }
  },
  methods: {
    handleRemove() {},
    changeTimeslot() {},
    changeDelivery() {},
    ...mapActions({
      emptyCart: "cart/emptyCart",
      setCartItemInfo: "cart/setCartItemInfo",
      setCartInfo: "cart/setCartInfo"
    })
  }
};
</script>
