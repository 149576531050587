const routes = [
  {
    path: "/page/:pageName",
    name: "Page",

    component: () => import("@/views/wordpress/Page.vue")
  },
  {
    path: "/faq",
    meta: {
      breadCrumb: "Faq"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        name: "FaqList",
        path: "",
        component: () => import("@/views/wordpress/FaqList.vue")
      }
    ]
  },
  {
    path: "/ricette",
    meta: {
      breadCrumb: "Ricette"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        name: "RecipeList",
        path: "",
        component: () => import("@/views/wordpress/RecipeList.vue"),
        props: route => ({
          course: route.query.course,
          ingredients: route.query.ingredients,
          cuisine: route.query.cuisine,
          s: route.query.s
        })
      },
      {
        path: ":recipeName",
        name: "RecipeDetail",
        meta: {
          breadCrumb: "Dettaglio Ricetta"
        },
        component: () => import("@/views/wordpress/RecipeDetail.vue")
      }
    ]
  },
  // {
  //   path: "/news",
  //   meta: {
  //     breadCrumb: "News e curiosità"
  //   },
  //   component: {
  //     render(c) {
  //       return c("router-view");
  //     }
  //   },
  //   children: [
  //     {
  //       name: "NewsList",
  //       path: "",
  //       component: () => import("@/views/wordpress/NewsList.vue")
  //     },
  //     {
  //       path: ":newsName",
  //       name: "NewsDetail",
  //       meta: {
  //         breadCrumb: "Dettaglio News"
  //       },
  //       component: () => import("@/views/wordpress/NewsDetail.vue")
  //     }
  //   ]
  // },
  {
    path: "/form/:formId",
    name: "NinjaForms",
    component: () => import("@/commons/components/ninjaForms/NinjaForm.vue"),
    props: true
  }
];

export default routes;
