<template>
  <div class="login fill-height mt-1">
    <v-container fluid fill-height>
      <v-layout>
        <v-flex xs12 mt-5>
          <v-form ref="form" v-model="valid" @submit.prevent="handleSubmit">
            <v-card class="elevation-0 px-0 px-sm-7 pb-5">
              <v-img
                eager
                src="@/assets/tosano.svg"
                height="52"
                max-width="200"
                class="mx-auto"
                :alt="'logo Tosano Supermercati'"
              />
              <div class="text-h2 default--text text-center my-1">
                {{ $t("login.title") }}
              </div>
              <v-spacer></v-spacer>
              <v-card-text>
                <v-text-field
                  color="secondary"
                  ref="email"
                  v-model="email"
                  :rules="emailRules"
                  :error-messages="errors"
                  type="email"
                  label="Indirizzo email"
                  autocomplete="off"
                  required
                  outlined
                  dense
                  clearable
                  @blur="removeSpaces(email)"
                ></v-text-field>
                <v-text-field
                  color="secondary"
                  v-model="password"
                  label="Password"
                  autocomplete="off"
                  :append-icon="showPassword ? '$eye' : '$eyeOff'"
                  :type="showPassword ? 'text' : 'password'"
                  :rules="passwordRules"
                  @click:append="toggleShowPassword"
                  required
                  outlined
                  dense
                  clearable
                ></v-text-field>

                <div
                  class="d-flex flex-column flex-sm-row align-left align-sm-center justify-space-between remember-password"
                >
                  <v-checkbox
                    color="secondary"
                    class="ma-0 mb-7 mb-sm-0"
                    hide-details
                    v-model="rememberMe"
                  >
                    <template v-slot:label>
                      <div class="text-caption">
                        {{ $t("login.rememberOnDevice") }}
                      </div>
                    </template>
                  </v-checkbox>
                  <div
                    class="default--text font-weight-bold"
                    v-if="enableRegistration"
                  >
                    <a
                      @click="goToResetPassword"
                      class="default--text text-decoration-underline"
                      :class="$vuetify.breakpoint.xs ? 'default--text' : ''"
                    >
                      {{ $t("login.rememberPassword") }}
                    </a>
                  </div>
                </div>
                <!-- <div class="mt-6 text-center"> -->
                <!-- </div> -->
                <ResponseMessage :response="response" class="mt-4" />
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-btn
                      :loading="loading"
                      type="submit"
                      class="primary my-8"
                      large
                      block
                      depressed
                    >
                      {{ $t("login.buttonLogin") }}
                    </v-btn>

                    <RecaptchaDisclaimer @click="clicked" />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <div
                  class="d-flex flex-column w-100 align-center mt-2"
                  v-if="enableRegistration"
                >
                  <div class="text-h5 font-weight-bold mb-2">
                    {{ $t("login.messageRegister") }}
                  </div>
                  <v-btn
                    @click="handleRegister"
                    class="secondary--text"
                    large
                    outlined
                    block
                    depressed
                  >
                    {{ $t("login.buttonRegister") }}
                  </v-btn>
                  <v-btn
                    @click.stop.prevent="socialLoginGoogle()"
                    class="secondary--text mt-3"
                    large
                    outlined
                    block
                    depressed
                  >
                    {{ $t("login.googleRegisterLeft") }}
                    <img
                      src="/img_layout/login/google.svg"
                      class="mx-2 google-logo"
                    />
                    {{ $t("login.googleRegisterRight") }}
                  </v-btn>
                </div>
                <div
                  class="d-flex flex-column w-100 align-center mt-2"
                  v-else-if="!isCordova"
                >
                  <div class="text-h5 font-weight-bold  mb-2">
                    {{ $t("login.messageRegister") }}
                  </div>
                  <v-btn
                    href="https://www.latuaspesa.com/Registrazione"
                    class="secondary--text"
                    large
                    outlined
                    block
                    depressed
                    >{{ $t("login.buttonRegister") }}
                  </v-btn>
                </div>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<style scoped lang="scss">
.google-logo {
  width: 22px;
}
</style>

<script>
import ResponseMessage from "@/components/ResponseMessage";
import RecaptchaDisclaimer from "@/commons/components/RecaptchaDisclaimer.vue";

import clickHandler from "~/mixins/clickHandler";

import RegistrationService from "~/service/userService";
import SocialService from "~/service/socialService";

import { mapActions, mapGetters } from "vuex";
import { requiredValue, isEmail } from "~/validator/validationRules";

export default {
  name: "Login",
  props: {
    hideRegistration: { type: Boolean, required: false, default: false }
  },
  mixins: [clickHandler],
  components: {
    ResponseMessage,
    RecaptchaDisclaimer
  },
  data() {
    return {
      loading: null,
      showPassword: false,
      email: "",
      password: "",
      rememberMe: true,
      response: {},
      valid: true,
      lazy: true,
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      passwordRules: [requiredValue("Digitare la password")],
      errors: [],
      invalid: null
    };
  },
  computed: {
    enableRegistration() {
      return global.config.registration;
    }
  },
  methods: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),
    ...mapActions({
      doLogin: "cart/doLogin",
      loadCart: "cart/loadCart"
    }),
    async goToResetPassword() {
      let _this = this;
      // directly sending email recovery request if email value is ok
      if (_this.email && _this.$refs.email.valid) {
        try {
          const res = await RegistrationService.getResetPasswordToken(
            _this.email
          );
          if (res.response) {
            _this.$emit("submit", false);
          }
        } catch (err) {
          if (err.response) {
            _this.response = err.response.data.response;
          } else {
            _this.response = {
              errors: [{ error: "Email non trovata" }],
              infos: [],
              status: 0
            };
          }
        }
      } else {
        // navigation to password recovery page
        _this.$router.push({
          name: "ResetPasswordRequest"
        });
      }
      _this.$emit("submit", false);
    },
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    socialLoginGoogle() {
      if (this.isCordova) {
        let redirectBaseUrl =
          window.location.protocol +
          "//" +
          window.location.hostname +
          (window.location.port !== "80" &&
          window.location.port !== "443" &&
          window.location.port &&
          window.location.port !== ""
            ? ":" + window.location.port
            : "");

        let url =
          "https://accounts.google.com/o/oauth2/auth?client_id=" +
          global.config.googleClientId +
          "&redirect_uri=" +
          redirectBaseUrl +
          "/ebsn/api/social-redir/google&prompt=consent&response_type=code&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile";

        SocialService.handleGoogleOnApp(url);
      } else {
        SocialService.openGoogleLoginPage();
      }
      this.$emit("submit", false);
    },

    async handleSubmit(e) {
      e.preventDefault();
      let token = null;
      if (this.$recaptchaLoaded) {
        await this.$recaptchaLoaded();
        token = await this.$recaptcha("login");
      }
      if (this.password.length > 0) {
        try {
          this.loading = true;
          this.response = {};
          await this.doLogin({
            email: this.email,
            password: this.password,
            rememberMe: this.rememberMe,
            token: token
          });
          this.$emit("submit", true);
        } catch (response) {
          this.response = response;
        } finally {
          this.loading = false;
        }
      }
    },
    removeSpaces(val) {
      if (this.email && val == this.email) {
        this.email = val.replace(new RegExp(/\s/g), "");
      }
    },
    handleRegister() {
      this.$router.push({
        name: "RegistrationUser",
        path: "/registration-user"
      });
      this.$emit("submit", false);
    }
  }
};
</script>
