import { req, len } from "./commons";

function requiredValueNoMsg(error) {
  var errorMessage = error ? error : "";
  return function(v) {
    return req(v) || errorMessage;
  };
}

function requiredValue(error) {
  var errorMessage = error ? error : "Campo obbligatorio";
  return function(v) {
    return req(v) || errorMessage;
  };
}
function isGenericPhone(error) {
  //(HomePhone | CellPhoneIta | CellPhoneCh)
  var errorMessage = error ? error : "Digitare un numero di telefono valido";
  return function(v) {
    return (
      !req(v) ||
      /(^[0-9]{2,4}(\s)?[0-9]{3,6}$)|(^(([+]|00)\d{2})?([\s-])?(((3[0-9][0-9]))?(\d{7})|(\d{2})\s*(\d{3})\s*(\d{2})\s*(\d{2}))$)/i.test(
        v
      ) ||
      errorMessage
    );
  };
}

function genericPeopleName(error) {
  // this regular expression accept all european charater, virgoletta singola ', e spazi
  var errorMessage = error
    ? error
    : "Sono accettati caratteri alfabetici minuscoli e maiuscoli, virgoletta e spazi";
  return function(v) {
    return !req(v) || /(^[A-Za-zÀ-ÿ' ]+$)/i.test(v) || errorMessage;
  };

  // ^[A-Za-zÀ-ÿ' ]+$
}

function isMobilePhone(error) {
  // CellPhoneIta | CellPhoneCh
  var errorMessage = error ? error : "Digitare un numero di telefono valido";
  return function(v) {
    return (
      !req(v) ||
      /(^(([+]|00)\d{2})?([\s-])?(((3[0-9][0-9]))?(\d{7})|(\d{2})\s*(\d{3})\s*(\d{2})\s*(\d{2}))$)/i.test(
        v
      ) ||
      errorMessage
    );
  };
}
function isHomePhone(error) {
  //(HomePhone
  var errorMessage = error ? error : "Digitare un numero di telefono valido";
  return function(v) {
    return !req(v) || /(^[0-9]{2,4}(\s)?[0-9]{3,6}$)/i.test(v) || errorMessage;
  };
}
function isTelephone(error) {
  //(HomePhone
  var errorMessage = error ? error : "Digitare un numero di telefono valido";
  return function(v) {
    return !req(v) || /^[0-9]{2,4}(\s)?[0-9]{3,9}$/i.test(v) || errorMessage;
  };
}
function isCAP(error) {
  var errorMessage = error ? error : "Il CAP deve essere di 4 o 5 cifre";
  return function(v) {
    return !req(v) || /^[0-9]{4,5}$/.test(v) || errorMessage;
  };
}
function isIstatCode(error) {
  var errorMessage = error ? error : "Lo ZIP Code deve avere 10 cifre";
  return function(v) {
    return !req(v) || /^[0-9]{10}$/.test(v) || errorMessage;
  };
}
function isPasswordMinimumLength(error) {
  var errorMessage = error
    ? error
    : "La password deve essere lunga 6 caratteri";
  return function(v) {
    return !req(v) || /.{6,}/.test(v) || errorMessage;
  };
}
function isEmail(error) {
  var errorMessage = error ? error : "Digitare una e-mail valida";
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,11}$/;
  //const emailRegex = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
  return function(v) {
    return !req(v) || emailRegex.test(v) || errorMessage;
  };
}
function isCF(error) {
  var errorMessage = error ? error : "Formato non corretto";
  return function(v) {
    return (
      !req(v) ||
      /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/.test(
        v.toUpperCase()
      ) ||
      errorMessage
    );
  };
}
function fiscalCodeChecksum(error) {
  var errorMessage = error ? error : "Codice fiscale non valido";
  const set1 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const set2 = "ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const setpari = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const setdisp = "BAKPLCQDREVOSFTGUHMINJWZYX";
  const validi = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  return function(cf) {
    if (cf == "") return errorMessage;
    cf = cf.toUpperCase();
    if (cf.length != 16) return false;

    for (let i = 0; i < 16; i++) {
      if (validi.indexOf(cf.charAt(i)) == -1) return errorMessage;
    }

    let s = 0;
    for (let i = 1; i <= 13; i += 2) {
      s += setpari.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))));
    }
    for (let i = 0; i <= 14; i += 2) {
      s += setdisp.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))));
    }
    if (s % 26 != cf.charCodeAt(15) - "A".charCodeAt(0)) {
      return errorMessage;
    } else {
      return true;
    }
  };
}
function isCfIva(error) {
  var errorMessage = error ? error : "P.Iva e C.F. devono avere 11 cifre";
  return function(v) {
    return (
      !req(v) ||
      /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/.test(
        v.toUpperCase()
      ) ||
      /^[0-9]{11}$/.test(v) ||
      errorMessage
    );
  };
}
function isCfNumeric(error) {
  var errorMessage = error ? error : "P.Iva e C.F. devono avere 11 cifre";
  return function(v) {
    return !req(v) || /^[0-9]{11}$/.test(v) || errorMessage;
  };
}
function isSDICode(error) {
  var errorMessage = error ? error : "Codice SDI non valido";
  return function(v) {
    return !req(v) || /^[A-Z0-9]{6,7}$/.test(v) || errorMessage;
  };
}
function isDate(error) {
  var errorMessage = error ? error : "Formato della data gg/mm/aaaa";
  return function(v) {
    return (
      !req(v) ||
      /^(0[1-9]|1[012])[- /.] (0[1-9]|[12][0-9]|3[01])[- /.] (19|20)\d\d$/.test(
        v
      ) ||
      errorMessage
    );
  };
}
function isName(error) {
  var errorMessage = error ? error : "Accetta solo nomi validi";
  return function(v) {
    return (
      !req(v) ||
      /^([a-zA-Z\u00C0-\u017F\u00D1\u00F1]+(\s[a-zA-Z\u00C0-\u017F\u00D1\u00F1]+)?)$/.test(
        v
      ) ||
      errorMessage
    );
  };
}
function isRegexp(expr, error) {
  var errorMessage = error ? error : "Accetta solo nomi validi";
  var exp = expr;
  return function(v) {
    return !req(v) || exp.test(v) || errorMessage;
  };
}
function isAlpha(error) {
  //Accepts only alphabet characters.
  var errorMessage = error ? error : "Accetta solo caratteri dell'alfabeto";
  return function(v) {
    return !req(v) || /^[a-zA-Z]*$/.test(v) || errorMessage;
  };
}
function isAlphaNum(error) {
  //Accepts only alphanumerics.
  var errorMessage = error ? error : "Accetta solo caratteri alfanumerici";
  return function(v) {
    return !req(v) || /^[a-zA-Z0-9]*$/.test(v) || errorMessage;
  };
}
function isOnlyCharacterAndNumber(error) {
  var errorMessage = error
    ? error
    : "Sono ammessi solamente caratteri alfanumerici.";
  return function(v) {
    return !v || /^[A-zÀ-ú0-9. :'-]*$/.test(v) || errorMessage;
  };
}
function isInteger(error) {
  var errorMessage = error ? error : "Accetta solo numeri interi";
  return function(v) {
    return !req(v) || /(^[0-9]*$)|(^-[0-9]+$)/.test(v) || errorMessage;
  };
}
function isNumber(error) {
  var errorMessage = error ? error : "Accetta solo numeri";
  return function(v) {
    return !req(v) || /^\d*\.?\d+$/.test(v) || errorMessage;
  };
}
function isFloatingNumber(error) {
  var errorMessage = error
    ? error
    : "Accetta solo numeri interi o decimali separati da virgola";
  return function(v) {
    return !req(v) || /^([0-9])*([,]{0,1}[0-9])*$/.test(v) || errorMessage;
  };
}
function IdDocumentType(error) {
  var errorMessage = error ? error : "Accetta solo caratteri alfanumerici";
  return function(v) {
    return (
      !req(v) || /^[\da-zA-ZàÀ']+(?: [\da-zA-ZàÀ']+)*?$/.test(v) || errorMessage
    );
  };
}
function minNum(minNum, error) {
  // verifica se ci sono almeno n (minNum) numeri in una stringa
  var errorMessage = error
    ? error
    : "Deve contenere almeno " + minNum + " caratteri numerici";
  return function(v) {
    let regex = new RegExp("(.*?\\d){minNum,}".replace("minNum", minNum));
    return !req(v) || regex.test(v) || errorMessage;
  };
}
function minCapitalCharacter(minNum = 1, error) {
  // check if there is at least n (minNum) capital letter in a string, default 1 maiuscola
  var errorMessage = error
    ? error
    : "Deve contenere almeno " + minNum + " caratteri maiuscoli";
  return function(v) {
    let regex = new RegExp("(.*?[A-Z]){minNum,}".replace("minNum", minNum));
    return !req(v) || regex.test(v) || errorMessage;
  };
}
function minSymbolCharacter(minNum = 1, customSymbols, error) {
  // check if there is at least n (minNum) symbol (default W: non word characters) in a string, otherwise function accept custom symbols (example: "!@#$%^&*()_+-=[]{};':,./<>?|")
  var errorMessage = error
    ? error
    : customSymbols
    ? "Deve contenere almeno " +
      minNum +
      " di questi caratteri speciali: " +
      customSymbols
    : "Deve contenere almeno " + minNum + " caratteri speciali";
  return function(v) {
    let regex = customSymbols
      ? new RegExp(
          "(.*?[customSymbols]){minNum,}"
            .replace("customSymbols", customSymbols)
            .replace("minNum", minNum)
        )
      : new RegExp("(.*?\\W){minNum,}".replace("minNum", minNum));
    return !req(v) || regex.test(v) || errorMessage;
  };
}
function isLength(length, error) {
  var errorMessage = error
    ? error
    : "Il campo deve essere lungo " + length + "caratteri";
  var l = length;
  return function(v) {
    return !req(v) || len(v) == l || errorMessage;
  };
}
function minLength(length, error) {
  var errorMessage = error
    ? error
    : "Lunghezza minima " + length + " caratteri";
  var l = length;
  return function(v) {
    return !req(v) || len(v) >= l || errorMessage;
  };
}
function maxLength(length, error) {
  var errorMessage = error
    ? error
    : "Lunghezza massima " + length + " caratteri";
  var l = length;
  return function(v) {
    return !req(v) || len(v) <= l || errorMessage;
  };
}
function isUrl(error) {
  var errorMessage = error ? error : "Digitare un url valido";
  return function(v) {
    return (
      !req(v) ||
      /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
        v
      ) ||
      errorMessage
    );
  };
}
function startsWithCardPrefix(prefix, error) {
  var errorMessage = error ? error : "Numero di carta fedeltà non valido";
  return function(v) {
    // eslint-disable-next-line no-undef
    if (v && prefix && v.length > prefix.length) {
      return (
        !req(v) ||
        // eslint-disable-next-line no-undef
        v.indexOf(prefix) === 0 ||
        errorMessage
      );
    }
    return true;
  };
}

function isCheckboxTrue(error) {
  var errorMessage = error ? error : "Campo obbligatorio";
  return function(v) {
    if (v && v !== "true") {
      return errorMessage;
    }
    return true;
  };
}

function isCheckboxTrueCustom(error) {
  var errorMessage = error ? error : "Campo obbligatorio";
  return function(v) {
    //if (v ?? true !== 1) {
    if (v !== null && v !== "" && v !== undefined && v !== 1) {
      return errorMessage;
    }
    return true;
  };
}

function isGS1Chekdigit(error) {
  var errorMessage = error ? error : "Numero di carta fedeltà non valido";
  return function(v) {
    if (v && v.length > 0) {
      return (
        !req(v) ||
        parseInt(v.substring(v.length, v.length - 1)) === checkdigit(v) ||
        errorMessage
      );
    }
    return true;
  };
  function checkdigit(input) {
    let array = input.split("").reverse();

    let total = 0;
    let i = 1;
    array.shift();
    array.forEach(number => {
      number = parseInt(number);
      if (i % 2 === 0) {
        total = total + number;
      } else {
        total = total + number * 3;
      }
      i++;
    });

    return Math.ceil(total / 10) * 10 - total;
  }
}

export {
  requiredValueNoMsg,
  requiredValue,
  isGenericPhone,
  isMobilePhone,
  isHomePhone,
  isTelephone,
  isCAP,
  isIstatCode,
  isPasswordMinimumLength,
  isEmail,
  isCF,
  isCfIva,
  isDate,
  isSDICode,
  isName,
  isAlpha,
  isAlphaNum,
  isInteger,
  isNumber,
  isFloatingNumber,
  IdDocumentType,
  isUrl,
  isLength,
  minLength,
  maxLength,
  minNum,
  isCfNumeric,
  startsWithCardPrefix,
  isGS1Chekdigit,
  minCapitalCharacter,
  minSymbolCharacter,
  isCheckboxTrue,
  isCheckboxTrueCustom,
  isOnlyCharacterAndNumber,
  isRegexp,
  genericPeopleName,
  fiscalCodeChecksum
};
