<template>
  <v-container class="pa-0 secondary lighten-1 white--text">
    <div class="d-flex justify-space-between align-center my-5 pl-4">
      <div class="text-body-2 font-weight-bold white--text text-uppercase">
        {{ $t("links.title") }}
      </div>
      <!-- <v-btn
        large
        icon
        plain
        @click="$emit('close')"
        color="default"
        class="no-border"
      >
        <v-icon>$close</v-icon>
      </v-btn> -->
    </div>
    <div @click="clicked">
      <div
        no-gutters
        v-for="(link, index) in links"
        v-bind:key="index"
        :class="
          isCordovaPlatform(link.classes) ? 'd-none' : 'section white--text'
        "
      >
        <a
          :href="link.url"
          @click="onLinkClick"
          target="_blank"
          class="text-body-2 text-uppercase white--text text-decoration-none pl-4 font-weight-bold"
          >{{ link.title }}</a
        >
        <div
          v-for="(child, childIdx) in link.children"
          :key="childIdx"
          class="my-1 child px-4"
        >
          <!-- "socials" column not rendered on mobile app -->
          <div
            v-if="child.children.length > 0"
            class="d-flex flex-column"
            style="gap: 10px"
          >
            <!-- multiple items row: third level on wp menu -->
            <!-- simulate btn on wp: (v-btn primary v-btn--outlined v-btn--tile) (v-btn v-btn--outlined v-btn--tile) -->
            <a
              v-for="thirdLevelChild in child.children"
              :key="thirdLevelChild.ID"
              :href="thirdLevelChild.url"
              @click="onLinkClick"
              :target="thirdLevelChild.target"
              class="text-body-2 white--text text-decoration-none"
              :class="thirdLevelChild.classes"
            >
              {{ thirdLevelChild.title }}
            </a>
          </div>
          <!-- classic row -->
          <a
            v-else
            :href="child.url"
            @click="onLinkClick"
            :target="child.target"
            class="text-body-2 white--text text-decoration-none"
            :class="child.classes"
          >
            <span>{{ child.title }}</span>
          </a>
        </div>
      </div>
      <div class="d-flex flex-column white--text secondary lighten-3">
        <i18n path="footer.info" tag="span" class="copyright text-center py-4">
          <router-link
            class="white--text"
            :to="{
              name: 'Page',
              params: { pageName: 'contatti' }
            }"
          >
            {{ $t("footer.infoLink") }}
          </router-link>
        </i18n>
        <span class="copyright text-center">{{ $t("footer.copyright") }}</span>
        <i18n
          path="footer.version"
          tag="span"
          class="text-caption text-center py-4"
        >
          {{ version }}
        </i18n>
      </div>
    </div>
  </v-container>
</template>
<style scoped lang="scss">
.copyright {
  font-size: 12px;
  a {
    font-size: inherit;
  }
}
.section {
  border-bottom: 1px solid #e9e9e9;
  padding: 12px 0;
  &:first-of-type {
    padding-top: 0 !important;
  }
}
.v-btn {
  display: flex;
  margin-top: 3px;
  min-height: 28px;
}
</style>
<script>
import clickHandler from "~/mixins/clickHandler";
import page from "~/mixins/page";
export default {
  mixins: [clickHandler, page],
  props: {
    links: { type: Array }
  },
  computed: {
    version() {
      return global.config.version;
    }
  },
  methods: {
    isSocialLinksColumn(classes) {
      return classes.search("social") > -1;
    },
    isCordovaPlatform(classes) {
      return this.isSocialLinksColumn(classes) && this.cordovaPlatform;
    },
    onLinkClick(e) {
      this.$emit("close");
      if (e.currentTarget.className.includes("cookiebot-widget")) {
        e.stopPropagation();
        // eslint-disable-next-line no-undef
        Cookiebot.show();
      } else {
        this.$nextTick(() => {
          this.clicked(e);
        });
      }
    }
  },
  created() {
    console.log(this.links, this.bottomLinks);
  }
};
</script>
