<template>
  <v-list flat class="category-tree white">
    <v-list-group
      color="secondary"
      v-for="category in allCategories"
      :key="category.categoryId"
      class="category1"
      :class="{
        'category-custom': !category.skip
      }"
      append-icon=""
      @click="goToCategory(category)"
    >
      <template v-slot:activator>
        <v-list-item-title
          class="font-weight-bold text-uppercase default--text"
        >
          <router-link
            :to="{ name: 'Category', params: { pathMatch: category.slug } }"
            event
          >
            {{ category.name }}
          </router-link></v-list-item-title
        >
      </template>
      <template v-for="category2 in category.categories">
        <v-list-group
          color="secondary"
          sub-group
          class="category2"
          v-if="category2.categories"
          :key="category2.categoryId"
          prepend-icon=""
        >
          <template v-slot:activator>
            <v-list-item
              class="secondary--text px-0"
              :to="{
                name: 'Category',
                params: { pathMatch: category2.slug }
              }"
            >
              <v-list-item-title class="font-weight-bold secondary--text">
                {{ category2.name }}
              </v-list-item-title>
            </v-list-item>
          </template>

          <v-list-item
            v-for="category3 in category2.categories"
            :key="category3.categoryId"
            :to="{ name: 'Category', params: { pathMatch: category3.slug } }"
            class="category3"
          >
            <v-list-item-title>{{ category3.name }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-item
          v-else
          link
          :to="{ name: 'Category', params: { pathMatch: category2.slug } }"
          color="grey lighten-3"
          class="category2 font-weight-bold secondary--text"
          :key="category2.categoryId"
        >
          <v-list-item-title>{{ category2.name }}</v-list-item-title>
        </v-list-item>
      </template>
    </v-list-group>
  </v-list>
</template>
<style global lang="scss">
.category-tree.v-list--dense .v-list-item .v-list-item__icon {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
  margin-right: 12px !important;
}
.category-tree .category1 {
  .v-list-item {
    min-height: 40px;
  }
}
.category-tree .category2.v-list-group--sub-group .v-list-group__header {
  padding-left: 10px !important;
}

.category2 .v-list-item__title {
  padding-left: 10px !important;
}

.category3 .v-list-item__title {
  padding-left: 18px !important;
}
</style>
<script>
import deliveryReactive from "~/mixins/deliveryReactive";

import CategoryService from "@/commons/service/categoryService";

import each from "lodash/each";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "CategoryTree",
  mixins: [deliveryReactive],
  data() {
    return {
      expandedKeys: [],
      selectedKey: null,
      threeCategories: []
    };
  },
  computed: {
    ...mapState({
      categories: ({ category }) => category.categoryTree
    }),
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),
    allCategories() {
      if (
        this.threeCategories &&
        this.threeCategories.children &&
        this.categories
      ) {
        each(this.categories, category => {
          category.skip = true;
        });
        return this.categories?.concat(this.threeCategories.children);
      } else return this.categories;
    }
  },
  methods: {
    ...mapActions({
      reload: "category/loadCategoryTree"
    }),
    goToCategory(category) {
      this.$router.push({
        name: "Category",
        params: { pathMatch: category.slug }
      });
    },
    async getCategories() {
      try {
        this.threeCategories = await CategoryService.getCategoryBySlug(
          "category-tree",
          false
        );
      } catch (e) {
        console.log(e);
      }
    }
  },
  created() {
    this.reload();
    this.getCategories();
  }
};
</script>
<style scoped lang="scss">
.category-tree {
  a {
    color: inherit !important;
    text-decoration: inherit !important;
  }
  .category1 {
    border-bottom: 1px solid var(--v-grey-lighten1);
    .v-list-item__title {
      font-size: 12px;
      color: $text-color;
    }
    &.v-list-group--active {
      background-color: var(--v-secondary-base);
      .v-list-item__title > a {
        font-weight: 700;
        color: white !important;
      }
    }
  }
  .category2 {
    &.v-list-group--sub-group .v-list-group__header {
      padding-left: 10px !important;
      .v-list-item__title > a {
        color: var(--v-secondary-base) !important;
      }
    }
    background-color: var(--v-secondary-lighten5) !important;
    // &.v-list-item--active {
    //   .v-list-item__title {
    //     color: var(--v-secondary-base) !important;
    //   }
    // }
    .v-list-item__title {
      color: $secondary !important;
    }
  }
  .category3 {
    padding-left: 10px !important;
    min-height: 34px !important;
    background-color: white;
    &.v-list-item--active {
      background-color: $white;
      .v-list-item__title {
        color: var(--v-secondary-base) !important;
        font-weight: 700 !important;
      }
    }
  }
}
</style>
