import { handleCategoryNavigation } from "./routeFunctions";

let routes = [
  {
    //gestione gerarchica del catalogo per poter gestire il reset filters all'ingresso.
    path: "/catalog",
    component: {
      render(c) {
        return c("router-view");
      }
    },
    beforeEnter(to, from, next) {
      global.EventBus.$emit("resetFilters");
      next();
    },
    children: [
      {
        path: "/search",
        name: "Search",
        component: () => import("@/views/catalog/Search.vue"),
        props: route => ({
          query: route.params,
          parent_category_id: route.params.parent_category_id,
          filter: route.params.filter,
          mode: "search"
        })
        // beforeEnter(to, from, next) {
        //   handleCategoryNavigation("search", to, from, next);
        // }
      },
      {
        path: "/fast-search",
        name: "FastSearch",
        component: () => import("@/views/catalog/FastSearch.vue"),
        props: route => ({
          query: route.query.q
        }),
        beforeEnter(to, from, next) {
          handleCategoryNavigation("fast-search", to, from, next);
        }
      },
      {
        path: "/category/*",
        name: "Category",
        component: () => import("@/views/catalog/Category.vue"),
        meta: {
          keepAlive: true
        },
        //commento
        props: route => ({
          slug: route.params.pathMatch,
          categoryId: route.query.categoryId
        }),
        async beforeEnter(to, from, next) {
          await handleCategoryNavigation(to.params.pathMatch, to, from, next);
        }
      },
      {
        path: "/product/:slug",
        name: "Product",
        component: () => import("@/views/catalog/Product.vue"),
        props: true
        // beforeEnter(to, from, next) {
        //   let slug = to.params.slug;
        //   handleCategoryNavigation(slug, to, from, next);
        // }
      }
    ]
  }
];

export default routes;
