var render = function render(_c,_vm){return (_vm.props.product.priceDisplay)?_c('div',{staticClass:"price",class:[
    _vm.props.product.warehousePromo
      ? _vm.props.product.warehousePromo.view.cssClass
      : '',
    _vm.props.product.priceStandardDisplay && _vm.showStandard
      ? 'justify-space-between'
      : 'justify-center'
  ]},[(_vm.props.showStandard && _vm.props.product.priceStandardDisplay)?_c('div',{staticClass:"old-price grey lighten-2 mr-2 text-decoration-line-through"},[_vm._v(" "+_vm._s(_vm.parent.$n(_vm.props.product.priceStandardDisplay, "currency"))+" ")]):_vm._e(),_c('div',{staticClass:"cur-price",class:{ 'cur-price-promo': _vm.props.product.priceStandardDisplay }},[_vm._v(" "+_vm._s(_vm.parent.$n(_vm.props.product.priceDisplay, "currency"))+" "),(_vm.props.product.productInfos.TIPOLOGIA != 'Pezzo')?_c('span',{staticClass:"weight-unit"},[_vm._v("/"+_vm._s(_vm.props.product.weightUnitDisplay)+" ")]):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }