global.config = {
  google_api_key: process.env.VUE_APP_GOOGLE_API_KEY,
  googleClientId:
    "57462414387-1kgvn5h2ndpqve29re1p7919kmf8oah3.apps.googleusercontent.com",

  googleGeocodeParams: {
    components: "country:it|administrative_area:Veneto"
  },
  baseUrl: "",
  proximityDefaultDistance: "10000.0",
  showSectors: false,
  hashNotReload: ["/cart/", "/payment"],
  filterEmptyCategory: true,
  acceptAlternatives: false,
  dayWordsSaved: 30,
  version: process.env.VUE_APP_VERSION,
  minProfileLevel: 1,
  registration: true,
  checkVersionInterval: 1000 * 60 * 10, //ogni 10 minuti
  reloadAfterMillis: 1000 * 60 * 30, // dopo 30 minuti di inattività restarta la app
  reopenTimeFirstShopModal: 1000 * 60 * 60 * 24 * 30, // reopen dopo 30 giorni
  domainWhitelist: [
    "www.latuaspesa.com",
    "dev-tosano.digitelematica.org",
    "test.latuaspesa.com",
    "localhost"
  ],
  showInPopupCode: ["msg.cart.availability.errors"],
  dialogs: [],
  pushNotificationEnabled: true,
  urlScheme: "ebsntosano://",
  deeplinks: {
    "/profile/orders": "/profile/orders",
    "/profile/orders/:orderId": "/profile/orders/:orderId",
    "/checkout": "/checkout",
    "/search": "/search",
    "/product/:slug": "/product/:slug",
    "/category/*": "/category/*",
    "/page/:slug": "/page/:slug",
    "/reset-password-request": "/reset-password-request",
    "/reset-password-sent": "/reset-password-sent",
    "/reset-password": "/reset-password",
    "/reset-password-completed": "/reset-password-completed"
  },
  updateCart: false,
  dialogMaxWidth: 530,
  addAuthPaymentTypeIds: [12, 22, 27],
  addAuthPaymentTypePrebilledAmount: { 12: 1.01, 22: 0, 27: 0 },
  defaultStoreName: "La Tua Spesa - Tosano",
  analyticsClass: "analyticsServiceGTM",
  defaultPaymentTypeId: 12,
  defaultPageName: "La Tua Spesa - Tosano",
  recaptchaTokenEnabled: true,
  recaptchaEnabledEndpoints: ["/ebsn/api/auth/login"]
};
