<template functional>
  <div
    v-if="props.product.priceDisplay"
    class="price"
    :class="[
      props.product.warehousePromo
        ? props.product.warehousePromo.view.cssClass
        : '',
      props.product.priceStandardDisplay && showStandard
        ? 'justify-space-between'
        : 'justify-center'
    ]"
  >
    <div
      class="old-price grey lighten-2 mr-2 text-decoration-line-through"
      v-if="props.showStandard && props.product.priceStandardDisplay"
    >
      {{ parent.$n(props.product.priceStandardDisplay, "currency") }}
    </div>
    <div
      class="cur-price"
      :class="{ 'cur-price-promo': props.product.priceStandardDisplay }"
    >
      {{ parent.$n(props.product.priceDisplay, "currency") }}
      <span
        class="weight-unit"
        v-if="props.product.productInfos.TIPOLOGIA != 'Pezzo'"
        >/{{ props.product.weightUnitDisplay }}
      </span>
    </div>
  </div>
</template>
<style scoped lang="scss">
.price {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 24px;
  line-height: 1;
  color: $text-color;
  white-space: nowrap;
  .weight-unit {
    font-size: 12px;
    margin-left: -5px;
  }
  .cur-price {
    color: $white;
    background-color: var(--v-secondary-base);
    &.cur-price-promo {
      background-color: var(--v-primary-base);
    }
  }
  .old-price,
  .cur-price {
    // box-sizing: border-box;
    font-weight: bold;
    height: 40px;
    line-height: 0.7;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: $border-radius-root;
    width: 47%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    &:before {
      border-color: #000;
    }
    .descr {
      font-size: 9px;
      font-weight: normal;
    }
  }
  .other {
    white-space: nowrap;
    font-size: 12px;
    text-decoration: none !important;
  }
  .original {
    font-size: 16px;
  }
  .price-um {
    font-size: 10px;
    line-height: 15px;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .old-price,
    .price-um {
      font-size: 12px;
    }
    .cur-price {
      font-size: 14px;
    }
  }
}
.t_promo {
  .cur-price {
    background-color: var(--v-primary-base);
  }
}
.diagonal-strikethrough {
  position: relative;
}
.diagonal-strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 1px solid;
  border-color: inherit;

  -webkit-transform: rotate(-8deg);
  -moz-transform: rotate(-8deg);
  -ms-transform: rotate(-8deg);
  -o-transform: rotate(-8deg);
  transform: rotate(-8deg);
}
</style>
<script>
export default {
  name: "props.productPrice",
  props: {
    product: { type: Object, required: true },
    showStandard: { type: Boolean, default: true }
  }
};
</script>
