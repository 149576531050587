<template functional>
  <img
    v-if="props.addressClass"
    class="address-class"
    :src="props.addressClass.iconSource"
    height="22"
    contain
    :alt="`logo ${props.addressClass.name}`"
    :title="`${props.addressClass.name}`"
  />
</template>
<style lang="scss">
.address-class.store-logo {
  max-height: 26px;
  padding: 0 2px;
}
</style>
<script>
export default {
  name: "AddressClass",
  props: { addressClass: { type: Object, required: true } }
};
</script>
