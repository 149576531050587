import { render, staticRenderFns } from "./MenuLinksList.vue?vue&type=template&id=49c85258&scoped=true"
import script from "./MenuLinksList.vue?vue&type=script&lang=js"
export * from "./MenuLinksList.vue?vue&type=script&lang=js"
import style0 from "./MenuLinksList.vue?vue&type=style&index=0&id=49c85258&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49c85258",
  null
  
)

export default component.exports